import React, { useState } from "react";
import { ReactComponent as SettingsBro } from "../../assets/bros/Settings-bro 1.svg";
import { ReactComponent as BuildIcon } from "../../assets/icons/build.svg";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../utils/colors";
import { wrapInStrongTag } from "../../utils/html";
import Flex from "../Flex";
import Icon from "../Icon";
import ManualDataModal from "../Modals/ManualDataModal";
import PageSectionTitle from "../PageSectionTitle";
import SimpleButton from "../RsuiteWrapper/SimpleButton";
import InterTag from "../Text/Inter";
import InitModal from "./initModal";

const buildTransKey = (keyword: string) =>
  `components.getting_started.${keyword}`;

interface iGettingStartedWrappedProps
  extends Pick<iProps, "onInsert" | "startModalOpen"> {}

const GettingStartedWrapped: React.FC<iGettingStartedWrappedProps> = ({
  onInsert,
  startModalOpen = false,
}) => {
  const { trans } = useLocalizationState();
  const [modals, setModals] = useState({
    init: startModalOpen,
    insert: false,
    final: false,
  });

  return (
    <>
      <InitModal
        open={modals.init}
        onClose={() => setModals({ init: false, insert: false, final: false })}
        onContinue={() =>
          setModals({ init: false, insert: true, final: false })
        }
      />
      <ManualDataModal
        open={modals.insert}
        onClose={(action) => {
          switch (action) {
            case "created":
              setModals({ init: false, insert: false, final: true });
              onInsert();
              break;
            case "back":
              setModals({ init: true, insert: false, final: false });
              break;
            case "exit":
              setModals({ init: false, insert: false, final: false });
              break;
          }
        }}
      />
      <Flex center middle>
        <SimpleButton
          appearance="primary"
          onClick={() => setModals({ init: true, insert: false, final: false })}
          text={{
            text: trans(buildTransKey("start")),
            bold: true,
            size: 20,
            color: COLORS.white,
          }}
          icon={{ Element: BuildIcon, fill: COLORS.white, size: 24 }}
        />
      </Flex>
    </>
  );
};

interface iProps {
  onInsert(): void;
  startModalOpen: boolean;
}

const GettingStarted: React.FC<iProps> = ({ onInsert, startModalOpen }) => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={trans(buildTransKey("title"))}
        description={trans(buildTransKey("description"))}
        icon={BuildIcon}
      />
      <Flex column middle gap={12}>
        <Flex center style={{ marginTop: "16px" }}>
          <Icon Element={SettingsBro} size={250} fill={COLORS.white} />
        </Flex>
        <InterTag wrap text={trans(buildTransKey("top_text"))} />
        {[0, 1, 2].map((i) => (
          <InterTag
            wrap
            text={`${wrapInStrongTag(
              trans(buildTransKey(`items[${i}].label`))
            )}: ${trans(buildTransKey(`items[${i}].description`))}`}
            asHTML
          />
        ))}

        <InterTag wrap text={trans(buildTransKey("bottom_text"))} />
        <InterTag wrap text={trans(buildTransKey("go_on_journey"))} />
      </Flex>
      <GettingStartedWrapped {...{ onInsert, startModalOpen }} />
    </Flex>
  );
};

export default GettingStarted;

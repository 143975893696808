import React from "react";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import NoPermissions from "../../../components/Pages/NoPermissions";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import EditDescription from "./editDescription";
import EditName from "./editName";
import PhotoGallery from "./photoGallery";

interface iHotelProfileWrappedProps {
  canWrite: boolean;
}

const HotelProfileWrapped: React.FC<iHotelProfileWrappedProps> = ({
  canWrite,
}) => {
  return (
    <div>
      <Flex column gap={40}>
        <EditName {...{ canWrite }} />
        <EditDescription {...{ canWrite }} />
        <PhotoGallery {...{ canWrite }} />
      </Flex>
      <PageBottomPadding />
    </div>
  );
};

const HotelProfile: React.FC = () => {
  const profilePermissions = useProfilePermissions();

  return (
    <>
      <TopBar page="pages.hotel_profile.title" />
      {profilePermissions.readGuestAppData ? (
        <IpValidatorGuard>
          <HotelProfileWrapped
            canWrite={profilePermissions.writeGuestAppData}
          />
        </IpValidatorGuard>
      ) : (
        <NoPermissions />
      )}
    </>
  );
};

export default HotelProfile;

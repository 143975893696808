import React, { useContext, useEffect, useState } from "react";
import Flex from "../../../../components/Flex";
import GettingStarted, {
  GettingStartedFinalModal,
} from "../../../../components/GettingStarted";
import ManualDataModal from "../../../../components/Modals/ManualDataModal";
import RoadmapTable from "../../../../components/RoadmapTable";
import SimpleButton from "../../../../components/RsuiteWrapper/SimpleButton";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import useProfilePermissions from "../../../../hooks/useProfilePermissions";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { SustainabilityContext } from "../context";
import Benchmark from "./benchmark";
import Indicators from "./indicators";

interface iProps {
  dataTypeToggle: any;
}

const ManualData: React.FC<iProps> = ({ dataTypeToggle }) => {
  const { trans } = useLocalizationState();
  const { setDataTypeToggleDisabled } = useContext(SustainabilityContext);
  const [manualDataModal, setManualDataModal] = useState(false);
  const profilePermissions = useProfilePermissions();
  const {
    hotel: { manualData },
  } = useHotelManualDataState();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: manualData.length > 0,
    initialStatus: manualData.length
      ? REQUEST_STATUS.PENDING
      : REQUEST_STATUS.IDLE,
  });

  const [gettingStartedFinalModal, setGettingStartedFinalModal] =
    useState(false);

  useEffect(() => {
    setDataTypeToggleDisabled({
      conversionFactorsRequest: conversionFactorsRequest.isLoading,
    });
  }, [conversionFactorsRequest.isLoading, setDataTypeToggleDisabled]);

  return (
    <>
      <GettingStartedFinalModal
        open={gettingStartedFinalModal}
        onClose={() => setGettingStartedFinalModal(false)}
      />
      <ManualDataModal
        open={manualDataModal}
        onClose={() => {
          setManualDataModal(false);
        }}
      />
      {manualData.length === 0 ? (
        <GettingStarted
          startModalOpen={true}
          onInsert={() => setGettingStartedFinalModal(true)}
        />
      ) : (
        <div>
          <SecondaryTopBar>
            <Flex row gap={8} middle>
              {dataTypeToggle && <Flex>{dataTypeToggle}</Flex>}
              {profilePermissions.writeManualData && (
                <SimpleButton
                  appearance="primary"
                  text={{
                    text: trans(
                      "components.manual_data.global_add_entry.label"
                    ),
                    color: COLORS.white,
                    size: 12,
                  }}
                  onClick={() => setManualDataModal(true)}
                />
              )}
            </Flex>
          </SecondaryTopBar>
          <Flex column gap={40}>
            <Benchmark {...{ conversionFactorsRequest }} />
            <Indicators {...{ conversionFactorsRequest }} />
            <RoadmapTable />
          </Flex>
        </div>
      )}
    </>
  );
};

export default ManualData;

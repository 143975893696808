import { at, round, sum } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "rsuite";
import { ReactComponent as DomainIcon } from "../../../../assets/icons/domain.svg";
import { ReactComponent as EcoIcon } from "../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as PaymentsIcon } from "../../../../assets/icons/payments.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import Flex from "../../../../components/Flex";
import Icon from "../../../../components/Icon";
import PageSectionTitle from "../../../../components/PageSectionTitle";
import SkeletonText from "../../../../components/Skeleton/SkeletonText";
import InterTag from "../../../../components/Text/Inter";
import useHotelsListState from "../../../../context/Hotel/hooks/hotelState/useHotelsListState";
import { nHotel } from "../../../../context/Hotel/interfaces";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useProfileDispatch from "../../../../context/Profile/hooks/useProfileDispatch";
import useGetMeasures from "../../../../hooks/apiRequests/useGetMeasures";
import { tIconElement } from "../../../../interfaces/icon";
import { TE, TW } from "../../../../models/measures";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertEuro,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../../utils/convertUnits";
import { nUnitConverter } from "../../../../utils/convertUnits/interfaces";
import { thousandsSeparation } from "../../../../utils/numbers";

interface iHotelCardProps {
  hotel: nHotel.tHotelAugmented;
}

const HotelCard: React.FC<iHotelCardProps> = ({ hotel }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { setActiveHotel } = useProfileDispatch();
  const [activeSlide, setActiveSlide] = useState(0);
  const measuresRequest = useGetMeasures(
    hotel,
    {
      measures: [TE, TW],
      from: moment().subtract(1, "month").startOf("month").toISOString(),
      to: moment().subtract(1, "month").endOf("month").toISOString(),
    },
    { doRequest: hotel.ipValidated }
  );

  if (!hotel.ipValidated) {
    return (
      <div style={{ width: "420px" }}>
        <Flex
          style={{ padding: "16px", borderRadius: "8px", cursor: "pointer" }}
          className="hover-box-shadow"
          column
          color={COLORS.white}
          onClick={(e: any) => {
            setActiveHotel(hotel._id);
            setTimeout(() => {
              navigate("/overview");
            }, 200);
          }}
        >
          <Flex column gap={16}>
            <Flex row gap={16} style={{ minHeight: "100px" }}>
              {hotel.images.length > 0 && (
                <img
                  style={{ borderRadius: "8px" }}
                  src={hotel.images[0]}
                  width={"96px"}
                  height={"96px"}
                  alt={hotel.name}
                />
              )}
              <Flex column gap={4}>
                <InterTag
                  wrap
                  text={hotel.name}
                  color={COLORS.secondary}
                  size={20}
                />
                <InterTag
                  wrap
                  text={[
                    hotel.location.county,
                    hotel.location.district,
                    hotel.location.country,
                  ]
                    .filter((v) => v)
                    .join(", ")}
                  color={COLORS.gray_400}
                  size={14}
                />
              </Flex>
            </Flex>
            <InterTag
              color={COLORS.error}
              size={14}
              text={trans("Not permitted to access via this IP address")}
            />
          </Flex>
        </Flex>
      </div>
    );
  }

  const renderSlide1 = () => {
    const renderValues = () => {
      const list: {
        key: "co2" | "costs";
        label: string;
        icon: tIconElement;
        converter: nUnitConverter.tFunction;
      }[] = [
        {
          key: "co2",
          label: trans("CO<sub>2</sub>"),
          icon: EcoIcon,
          converter: convertMassUnit,
        },
        {
          key: "costs",
          label: trans("Costs"),
          icon: PaymentsIcon,
          converter: convertEuro,
        },
      ];
      return (
        <Flex center gap={40}>
          {list.map(({ icon, key, label, converter }) => {
            const renderValue = () => {
              const converted = converter(sum(measuresRequest.data[key]));

              return (
                <Flex row gap={4} bottom>
                  <InterTag
                    size={36}
                    text={thousandsSeparation(round(converted.value, 2))}
                    color={COLORS.secondary}
                  />
                  <InterTag
                    size={16}
                    text={converted.unitInHTML}
                    asHTML
                    color={COLORS.secondary}
                  />
                </Flex>
              );
            };

            return (
              <Flex column key={key} gap={8} middle>
                <Flex row gap={4} middle>
                  <Icon Element={icon} size={24} color={COLORS.secondary} />
                  <InterTag
                    text={label}
                    size={20}
                    asHTML
                    color={COLORS.secondary}
                  />
                </Flex>

                <div>
                  {measuresRequest.isLoading ? (
                    <SkeletonText height={36} width={80} />
                  ) : (
                    renderValue()
                  )}
                </div>
              </Flex>
            );
          })}
        </Flex>
      );
    };
    return (
      <Flex gap={16} column>
        <Flex row gap={16}>
          {hotel.images.length > 0 && (
            <img
              style={{ borderRadius: "8px" }}
              src={hotel.images[0]}
              width={"96px"}
              height={"96px"}
              alt={hotel.name}
            />
          )}
          <Flex column gap={4}>
            <InterTag
              wrap
              text={hotel.name}
              colro={COLORS.secondary}
              size={20}
            />
            <InterTag
              wrap
              text={[
                hotel.location.county,
                hotel.location.district,
                hotel.location.country,
              ]
                .filter((v) => v)
                .join(", ")}
              color={COLORS.gray_400}
              size={14}
            />
          </Flex>
        </Flex>
        {renderValues()}
      </Flex>
    );
  };

  const renderSlide2 = () => {
    const renderValues = () => {
      const list: {
        key:
          | "co2"
          | "costs"
          | "grouped.te.measurements"
          | "grouped.tw.measurements";
        label: string;
        icon: tIconElement;
        converter: nUnitConverter.tFunction;
      }[] = [
        {
          key: "co2",
          label: trans("Footprint"),
          icon: EcoIcon,
          converter: convertMassUnit,
        },
        {
          key: "costs",
          label: trans("Costs"),
          icon: PaymentsIcon,
          converter: convertEuro,
        },
        {
          key: "grouped.te.measurements",
          label: trans("Electricity"),
          icon: ElectricBoltIcon,
          converter: convertEnergyUnit,
        },
        {
          key: "grouped.tw.measurements",
          label: trans("Water"),
          icon: WaterDropIcon,
          converter: convertVolumeUnit,
        },
      ];
      return (
        <Flex column gap={12} style={{ padding: "0 12px" }}>
          {list.map(({ icon, key, label, converter }) => {
            const renderValue = () => {
              const converted = converter(
                sum(at(measuresRequest.data, key)[0] as number[])
              );

              return (
                <Flex row gap={4} left>
                  <InterTag
                    size={14}
                    text={thousandsSeparation(round(converted.value, 2))}
                    color={COLORS.secondary}
                  />
                  <InterTag
                    size={14}
                    text={converted.unitInHTML}
                    asHTML
                    color={COLORS.secondary}
                  />
                </Flex>
              );
            };

            return (
              <Flex row key={key} between middle>
                <Flex one row gap={4} middle>
                  <Icon Element={icon} size={18} color={COLORS.secondary} />
                  <InterTag
                    text={label}
                    size={14}
                    asHTML
                    color={COLORS.secondary}
                  />
                </Flex>

                <Flex one>
                  {measuresRequest.isLoading ? (
                    <SkeletonText height={14} width={70} />
                  ) : (
                    renderValue()
                  )}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      );
    };

    return (
      <Flex gap={16} column>
        <Flex row gap={16}>
          <Flex column gap={4}>
            <InterTag
              wrap
              text={hotel.name}
              colro={COLORS.secondary}
              size={20}
            />
            <InterTag
              wrap
              text={[
                hotel.location.county,
                hotel.location.district,
                hotel.location.country,
              ]
                .filter((v) => v)
                .join(", ")}
              color={COLORS.gray_400}
              size={14}
            />
          </Flex>
        </Flex>
        {renderValues()}
      </Flex>
    );
  };

  return (
    <div style={{ width: "420px" }}>
      <Flex
        className="hover-box-shadow"
        style={{
          padding: "16px",
          paddingBottom: 0,
          borderRadius: "8px",
          cursor: "pointer",
        }}
        column
        color={COLORS.white}
        onClick={(e: any) => {
          setActiveHotel(hotel._id);
          setTimeout(() => {
            navigate("/overview");
          }, 200);
        }}
      >
        <Carousel
          onClick={(e: any) => {
            if (
              e.target.className.indexOf("rs-carousel-label") > -1 ||
              e.target.tagName.toLowerCase() === "input"
            ) {
              e.stopPropagation();
            }
          }}
          onSelect={(index, e) => {
            e.stopPropagation();
            setActiveSlide(index);
          }}
          activeIndex={activeSlide}
          shape="bar"
          style={{
            backgroundColor: "transparent",
            height: "250px",
          }}
        >
          {renderSlide1()}
          {renderSlide2()}
        </Carousel>
      </Flex>
    </div>
  );
};

interface iProps {
  period: [Date, Date];
}

const Live: React.FC<iProps> = ({ period }) => {
  const { hotels } = useHotelsListState();
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={trans("pages.properties.sections.overview.title")}
        description={trans("pages.properties.sections.overview.description")}
        icon={DomainIcon}
      />
      <Flex wrap gap={24}>
        {hotels.map((hotel) => (
          <HotelCard key={hotel._id} {...{ hotel }} />
        ))}
      </Flex>
    </Flex>
  );
};

export default Live;

import React, { useContext } from "react";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as QueryStatsIcon } from "../../../../assets/icons/query_stats.svg";
import ConsumptionPageSpecific from "../../../../components/ConsumptionPageSpecific";
import Flex from "../../../../components/Flex";
import PageBottomPadding from "../../../../components/PageBottomPadding";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import InterTag from "../../../../components/Text/Inter";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import useHotelMeasuresTrackedState from "../../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePeriodState from "../../../../hooks/usePeriodState";
import { TE } from "../../../../models/measures";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  FORCE_ENERGY_MEASURE_UNIT,
} from "../../../../utils/convertUnits/";
import { ConsumptionContext } from "../../consumption/context";

const LiveData: React.FC<{ dataTypeToggle: JSX.Element | null }> = ({
  dataTypeToggle,
}) => {
  const { setDataTypeToggleDisabled } = useContext(ConsumptionContext);
  const { trans } = useLocalizationState();
  const {
    getMeasuresTracked,
    hotel: { spaces, energyToCurrency, energyToFootprint },
  } = useHotelMeasuresTrackedState();
  const [period, setPeriod] = usePeriodState();

  const trackedMeasures = getMeasuresTracked(spaces.map((s) => s._id));

  return (
    <div>
      <SecondaryTopBar>
        <Flex row gap={8} middle>
          {dataTypeToggle}
          <SimpleDateRangePicker value={period} onChange={setPeriod} />
        </Flex>
      </SecondaryTopBar>
      <div>
        {trackedMeasures.te ? (
          <ConsumptionPageSpecific
            measuresRequestStatusTracker={(status) => {
              setDataTypeToggleDisabled({
                electricityMeasuresRequest: status === REQUEST_STATUS.PENDING,
              });
            }}
            {...{
              period,
              mainMeasureKey: TE,
              overviewSection: {
                title: trans("pages.electricity.live.sections.overview.title"),
                description: trans(
                  "pages.electricity.live.sections.overview.description"
                ),
                icon: QueryStatsIcon,
              },
              spaceListSection: {
                title: trans("pages.electricity.live.sections.list.title"),
                description: trans(
                  "pages.electricity.live.sections.list.description"
                ),
                icon: ElectricBoltIcon,
              },
              converter: convertEnergyUnit,
              roadmapFilter: "electricity",
              tableHeader: {
                icon: ElectricBoltIcon,
                name: trans("general.measures.electricity"),
              },
              toCurrency: energyToCurrency,
              toFootprint: energyToFootprint,
              mainUnit: FORCE_ENERGY_MEASURE_UNIT,
            }}
          />
        ) : (
          <Flex>
            <InterTag
              text={trans("pages.electricity.live.not_measured")}
              size={16}
              color={COLORS.secondary}
            />
          </Flex>
        )}
      </div>
      <PageBottomPadding />
    </div>
  );
};

export default LiveData;

import { MomentInput } from "moment";
import React from "react";
import useHotelMeasuresTrackedState from "../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import { tHotelSpaceId } from "../../models/hotel";
import {
  MEASURES_WATER_SPECIFIC,
  tMeasureWaterSpecific,
  TW,
} from "../../models/measures";
import {
  convertVolumeUnit,
  DEFAULT_WATER_MEASURE_UNIT,
} from "../../utils/convertUnits/";
import MeasureConsumption, {
  iMeasureConsumptionProps,
} from "../MeasureConsumption";

interface iProps
  extends Pick<iMeasureConsumptionProps, "measuresRequestStatusTracker"> {
  from?: MomentInput;
  to?: MomentInput;
  spaces?: tHotelSpaceId[];
}

const MeasureConsumptionWater: React.FC<iProps> = ({
  from,
  to,
  spaces,
  measuresRequestStatusTracker,
}) => {
  const { getMeasuresTracked } = useHotelMeasuresTrackedState();

  const trackedMeasures = getMeasuresTracked(spaces);

  const specificMeasures: { measureKey: tMeasureWaterSpecific }[] = [];
  MEASURES_WATER_SPECIFIC.forEach((m) => {
    if (trackedMeasures[m]) specificMeasures.push({ measureKey: m });
  });

  return (
    <MeasureConsumption
      {...{
        measuresRequestStatusTracker,
      }}
      spaces={spaces}
      mainMeasureKey={TW}
      specificMeasures={specificMeasures}
      converter={convertVolumeUnit}
      binUnit="day"
      binValue={1}
      mainUnit={DEFAULT_WATER_MEASURE_UNIT}
      from={from}
      to={to}
    />
  );
};

export default MeasureConsumptionWater;

import { find, floor, min, round } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import { Loader, Table } from "rsuite";
import { ReactComponent as DateRangeIcon } from "../../../../assets/icons/date_range.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as EventRepeatIcon } from "../../../../assets/icons/event_repeat.svg";
import { ReactComponent as PaymentsIcon } from "../../../../assets/icons/payments.svg";
import TrendingComparison from "../../../../components/Comparions/trendingComparison";
import Flex from "../../../../components/Flex";
import PageSectionTitle from "../../../../components/PageSectionTitle";
import BaseCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleTextCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../../components/Text/Inter";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useScreenSize from "../../../../hooks/useScreenSize";
import { tHotelManualData } from "../../../../models/hotel";
import { COLORS } from "../../../../utils/colors";
import { calcNumberOfNights } from "../../../../utils/dates";
import {
  TABLE_HEADER_HEIGHT,
  TABLE_ROW_HEIGHT,
} from "../../../../utils/tables";

type tTableItem = tHotelManualData & { index: number; homologueId: string };

interface iShowcasingWrapped2Props {
  outletContainerWidth: number;
  tableData: tTableItem[];
}

const ShowcasingWrapped2: React.FC<iShowcasingWrapped2Props> = ({
  outletContainerWidth,
  tableData,
}) => {
  const { trans } = useLocalizationState();

  const options = (
    dataKey: string,
    space: tTableItem
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];
    return options;
  };

  const tableHeight =
    (min([12, tableData.length]) as number) * TABLE_ROW_HEIGHT.SM +
    TABLE_HEADER_HEIGHT;

  const periodWidth = floor(0.2 * outletContainerWidth);
  const electricityWidth = floor(0.15 * outletContainerWidth);
  const costsWidth = floor(0.15 * outletContainerWidth);
  const lastPeriodWidth = floor(0.15 * outletContainerWidth);
  const homologueWidth = floor(0.15 * outletContainerWidth);

  return (
    <Flex column>
      <div className="table-wrapper">
        <Table
          id="TABLE.ELECTRICITY.SHOWCASING"
          data={tableData}
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.SM}
          headerHeight={TABLE_HEADER_HEIGHT}
        >
          <Table.Column width={periodWidth}>
            <SimpleHeaderCell
              name={trans("general.period")}
              icon={DateRangeIcon}
            />
            <SimpleTextCell
              dataKey="period"
              textFunction={({ from, to }: tTableItem) =>
                trans("general.x_date_to_y_date", {
                  parameters: [
                    moment(from).format("DD MMM YYYY"),
                    moment(to).format("DD MMM YYYY"),
                  ],
                })
              }
            />
          </Table.Column>
          <Table.Column width={electricityWidth}>
            <SimpleHeaderCell
              name={trans("general.measures.electricity")}
              icon={ElectricBoltIcon}
            />
            <SimpleTextCell
              textFunction={(item: tTableItem) => {
                if (!item.electricity) return "-";
                return `${item.electricity?.totalKWh} kWh`;
              }}
              descriptionFunction={(item: tTableItem) => {
                if (!item.electricity) return null;
                const numberOfNights = calcNumberOfNights(item.from, item.to);
                return `${round(
                  item.electricity?.totalKWh / numberOfNights,
                  2
                )} kWh/${trans("general.day")}`;
              }}
              dataKey="electricity"
            />
          </Table.Column>
          <Table.Column width={costsWidth}>
            <SimpleHeaderCell
              name={trans("general.costs")}
              icon={PaymentsIcon}
            />
            <SimpleTextCell
              textProps={() => ({ asHTML: true })}
              textFunction={(item: tTableItem) => {
                if (!item.electricity) return "-";
                return `${item.electricity.price} €`;
              }}
              descriptionFunction={(item: tTableItem) => {
                if (!item.electricity) return null;
                const numberOfNights = calcNumberOfNights(item.from, item.to);
                return `${round(
                  item.electricity.price / numberOfNights,
                  2
                )} €/${trans("general.day")}`;
              }}
              dataKey="price"
            />
          </Table.Column>
          <Table.Column width={lastPeriodWidth}>
            <SimpleHeaderCell
              name={trans("general.period_previous")}
              icon={EventRepeatIcon}
            />
            <BaseCell dataKey="lastPeriod">
              {(rowData: tTableItem) => {
                const { index } = rowData;
                if (!rowData.electricity)
                  return <InterTag text="-" size={12} color={COLORS.gray} />;
                if (index + 1 === tableData.length)
                  return <InterTag text="-" size={12} color={COLORS.gray} />;

                const entry = tableData[index + 1];
                if (!entry || !entry.electricity)
                  return <InterTag text="-" size={12} color={COLORS.gray} />;

                return (
                  <TrendingComparison
                    value={rowData.electricity.totalKWh}
                    baseValue={entry.electricity.totalKWh}
                    size={12}
                    description={trans(
                      "components.trending_comparison.last_period_tooltip"
                    )}
                  />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={homologueWidth}>
            <SimpleHeaderCell
              name={trans("general.period_homologue")}
              icon={EventRepeatIcon}
            />
            <BaseCell>
              {(item: tTableItem) => {
                if (!item.electricity)
                  return <InterTag text="-" size={12} color={COLORS.gray} />;

                const homologueId = moment(item.from)
                  .subtract(1, "year")
                  .format("YYYY/MM");
                const homologue = find(
                  tableData,
                  (t) => t.homologueId === homologueId
                );

                if (!homologue || !homologue.electricity)
                  return <InterTag text="-" size={12} color={COLORS.gray} />;

                return (
                  <TrendingComparison
                    value={item.electricity.totalKWh}
                    baseValue={homologue.electricity.totalKWh}
                    size={12}
                  />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column>
            <SimpleHeaderCell name={trans("general.colors.green")} />
            <BaseCell>
              {(item: tTableItem) => {
                if (item.electricity?.isElectricityGreen)
                  return trans("general.yes");
                return trans("general.no");
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell name={{ text: "" }} />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </Flex>
  );
};

interface iShowcasingWrapped1Props {
  outletContainerWidth: number;
}

const ShowcasingWrapped1: React.FC<iShowcasingWrapped1Props> = ({
  outletContainerWidth,
}) => {
  const { getManualDataOrderedWithoutZeroValues } = useHotelManualDataState();

  const tableData: tTableItem[] = useMemo(() => {
    const items = getManualDataOrderedWithoutZeroValues(
      ["electricity"],
      "desc"
    );
    return items.map((item, index) => ({
      ...item,
      homologueId: moment(item.from).format("YYYY/MM"),
      index,
    }));
  }, [getManualDataOrderedWithoutZeroValues]);

  return <ShowcasingWrapped2 {...{ outletContainerWidth, tableData }} />;
};

interface iProps {}

const Showcasing: React.FC<iProps> = () => {
  const { outlet } = useScreenSize();
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={trans("pages.electricity.archive.sections.showcase.title")}
        description={trans(
          "pages.electricity.archive.sections.showcase.description"
        )}
        icon={ElectricBoltIcon}
      />
      {!outlet ? (
        <Flex>
          <Loader size="lg" />
        </Flex>
      ) : (
        <ShowcasingWrapped1 outletContainerWidth={outlet.width} />
      )}
    </Flex>
  );
};

export default React.memo(Showcasing);

import axios from "axios";
import moment, { MomentInput } from "moment";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DirectionsWalkIcon } from "../../../assets/icons/directions_walk.svg";
import ReloadButton from "../../../components/Buttons/ReloadButton";
import TrendingComparison from "../../../components/Comparions/trendingComparison";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import SkeletonText from "../../../components/Skeleton/SkeletonText";
import InterTag from "../../../components/Text/Inter";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest, {
  tUseGetRequest,
} from "../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import { tHotelId } from "../../../models/hotel";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { renderRequestedData } from "../../../utils/render";

type tCheckInData = {
  checkIn: { total: number; toDate: number };
  checkOut: { total: number; toDate: number };
};

interface iProps {
  period: [Date, Date];
}

const CheckInCheckOut: React.FC<iProps> = ({ period }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { hotelId, activeSubscriptionIsStarter } = useHotelSubscriptionState();

  const checkInRequest = useGetRequest<tCheckInData>({
    checkIn: { total: 0, toDate: 0 },
    checkOut: { total: 0, toDate: 0 },
  });
  const homologueCheckInRequest = useGetRequest<tCheckInData>({
    checkIn: { total: 0, toDate: 0 },
    checkOut: { total: 0, toDate: 0 },
  });

  const runCheckIn = useCallback(
    (
      request: tUseGetRequest<tCheckInData>,
      hotelId: tHotelId,
      ...period: [MomentInput, MomentInput]
    ) => {
      if (activeSubscriptionIsStarter) return;

      request.pending();
      axios
        .get(
          `${apiAddress(false)}/v2/hotels/${hotelId}/occupancy/check-in-out`,
          {
            params: {
              from: moment(period[0]).toISOString(),
              to: moment(period[1]).toISOString(),
            },
          }
        )
        .then((res) => {
          const {
            data: {
              data: { checkIn, checkOut },
            },
          } = res;
          request.resolve({ checkIn, checkOut });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          request.reject(error);
        });
    },
    [activeSubscriptionIsStarter, trans]
  );

  const runCheckInMain = useCallback(
    (hotelId: tHotelId, ...period: [MomentInput, MomentInput]) => {
      runCheckIn(checkInRequest, hotelId, ...period);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  const runCheckInHomologue = useCallback(
    (hotelId: tHotelId, ...period: [MomentInput, MomentInput]) => {
      runCheckIn(homologueCheckInRequest, hotelId, ...period);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  useEffectSafe(() => {
    if (hotelId) {
      const periodArgs: [MomentInput, MomentInput] = [
        moment(period[0]).toISOString(),
        moment(period[1]).toISOString(),
      ];
      const homologuePeriodArgs: [MomentInput, MomentInput] = [
        moment(period[0]).subtract(1, "year").toISOString(),
        moment(period[1]).subtract(1, "year").toISOString(),
      ];

      runCheckInMain(hotelId, ...periodArgs);
      runCheckInHomologue(hotelId, ...homologuePeriodArgs);
    }
  }, [hotelId, period, trans]);

  const list: { key: keyof tCheckInData; label: string }[] = [
    {
      key: "checkIn",
      label: trans(
        "pages.overview.sections.summary.cards.checkInOut.label_checkIn"
      ),
    },
    {
      key: "checkOut",
      label: trans(
        "pages.overview.sections.summary.cards.checkInOut.label_checkOut"
      ),
    },
  ];

  return (
    <Flex
      style={{ cursor: "pointer" }}
      column
      className="card-m hover-box-shadow"
      gap={20}
      onClick={() => {
        navigate("/guests");
      }}
    >
      <Flex row left middle gap={8}>
        <Icon Element={DirectionsWalkIcon} size={24} fill={COLORS.secondary} />
        <InterTag
          text={trans("pages.overview.sections.summary.cards.checkInOut.title")}
          size={20}
          color={COLORS.secondary}
        />
      </Flex>
      {renderRequestedData(checkInRequest.status, {
        pending: () => (
          <Flex row around>
            {list.map(({ key, label }) => {
              return (
                <Flex key={key} column gap={4}>
                  <InterTag text={label} size={12} color={COLORS.gray_400} />
                  <SkeletonText height={36} width={100} />
                  <SkeletonText height={24} width={100} />
                </Flex>
              );
            })}
          </Flex>
        ),
        resolved: () => (
          <Flex row around>
            {list.map(({ key, label }) => {
              return (
                <Flex key={key} column gap={4}>
                  <InterTag text={label} size={12} color={COLORS.gray_400} />
                  <InterTag
                    size={36}
                    color={COLORS.secondary}
                    text={`${checkInRequest.data[key].toDate}/${checkInRequest.data[key].total}`}
                  />
                  {renderRequestedData(homologueCheckInRequest.status, {
                    pending: () => <SkeletonText height={16} width={100} />,
                    resolved: () => (
                      <TrendingComparison
                        baseValue={homologueCheckInRequest.data[key].total}
                        value={checkInRequest.data[key].total}
                      />
                    ),
                  })}
                </Flex>
              );
            })}
          </Flex>
        ),
        rejected: () => (
          <Flex row middle gap={6}>
            <ReloadButton
              iconProps={{ size: 14 }}
              onClick={(e: any) => {
                e.stopPropagation();
                const periodArgs: [MomentInput, MomentInput] = [
                  moment(period[0]).toISOString(),
                  moment(period[1]).toISOString(),
                ];
                runCheckInMain(hotelId, ...periodArgs);
              }}
            />
            <InterTag
              size={14}
              color={COLORS.error}
              text={checkInRequest.error}
            />
          </Flex>
        ),
      })}
    </Flex>
  );
};

export default CheckInCheckOut;

import React, { useReducer } from "react";
import {
  DateRangePicker,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  SelectPicker,
} from "rsuite";
import { ReactComponent as HotelIcon } from "../../../assets/icons/hotel.svg";
import Modal2ActionButtons from "../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../components/Flex";
import InputWrapper from "../../../components/InputWrapper";
import PageSectionTitle from "../../../components/PageSectionTitle";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tHotelSpaceId } from "../../../models/hotel";
import { tReservationId } from "../../../models/reservation";

type tData = {
  guestEmail: string;
  guestName: string;
  space: tHotelSpaceId;
  period: [Date, Date] | null;
  numberOfGuests: number;
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: {
    guestEmail: "",
    guestName: "",
    space: "",
    period: null,
    numberOfGuests: 1,
  },
  errors: {
    guestEmail: "",
    guestName: "",
    space: "",
    period: "",
    numberOfGuests: "",
  },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iCreateUpdateReservationModalWrappedProps
  extends Pick<iProps, "onClose" | "onConfirm" | "isLoading" | "stayId"> {}

const CreateUpdateReservationModalWrapped: React.FC<
  iCreateUpdateReservationModalWrappedProps
> = ({ isLoading, onClose, onConfirm, stayId }) => {
  const { trans } = useLocalizationState();
  const { hotel } = useHotelState();

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleConfirm = () => {
    const {
      data: { guestEmail, period, space, numberOfGuests, guestName },
    } = state;

    const errors: Partial<tErrors> = {};

    if (!period) {
      errors.period = trans(
        "pages.guests.modals.create_update_stay.errors.no_period"
      );
    }
    if (!guestEmail) {
      errors.guestEmail = trans(
        "pages.guests.modals.create_update_stay.errors.no_email"
      );
    }
    if (!space) {
      errors.space = trans(
        "pages.guests.modals.create_update_stay.errors.no_space"
      );
    }
    if (numberOfGuests < 0) {
      errors.space = trans(
        "pages.guests.modals.create_update_stay.errors.invalid_number_of_guests"
      );
    }

    if (Object.keys(errors).length) {
      return dispatch({ type: "errors", errors });
    }

    if (guestEmail && period && space && numberOfGuests)
      onConfirm({ guestEmail, period, space, numberOfGuests, guestName });
  };

  const onChange = (key: keyof tData) => (value: any) => {
    dispatch({ type: "key value", key, value });
  };

  return (
    <>
      <Modal.Body>
        <Flex column gap={32}>
          <PageSectionTitle
            title={trans(
              stayId
                ? "pages.guests.modals.update_stay.title"
                : "pages.guests.modals.create_stay.title"
            )}
            icon={HotelIcon}
          />
          <Flex column gap={20}>
            <Flex row gap={8} between>
              <InputWrapper
                style={{ flexBasis: "50%" }}
                label={trans(
                  "pages.guests.modals.create_update_stay.inputs.email.label"
                )}
                error={state.errors.guestEmail}
                required
              >
                <Input
                  disabled={isLoading}
                  placeholder={trans(
                    "pages.guests.modals.create_update_stay.inputs.email.placeholder"
                  )}
                  value={state.data.guestEmail}
                  onChange={onChange("guestEmail")}
                />
              </InputWrapper>
              <InputWrapper
                style={{ flexBasis: "50%" }}
                label={trans(
                  "pages.guests.modals.create_update_stay.inputs.name.label"
                )}
                error={state.errors.guestName}
              >
                <Input
                  disabled={isLoading}
                  placeholder={trans(
                    "pages.guests.modals.create_update_stay.inputs.name.placeholder"
                  )}
                  value={state.data.guestName}
                  onChange={onChange("guestName")}
                />
              </InputWrapper>
            </Flex>
            <InputWrapper
              label={trans("general.number_of_guests")}
              error={state.errors.numberOfGuests}
              required
            >
              <InputNumber
                disabled={isLoading}
                value={state.data.numberOfGuests}
                onChange={onChange("numberOfGuests")}
                min={1}
              />
            </InputWrapper>
            <InputWrapper
              label={trans("general.space")}
              error={state.errors.space}
              required
            >
              <SelectPicker
                disabled={isLoading}
                data={hotel.spaces.map(({ _id, name }) => ({
                  label: name,
                  value: _id,
                }))}
                onChange={onChange("space")}
              />
            </InputWrapper>
            <InputWrapper
              label={trans(
                "pages.guests.modals.create_update_stay.inputs.check-in_check-out.label"
              )}
              error={state.errors.period}
              required
            >
              <DateRangePicker
                disabled={isLoading}
                placement="top"
                cleanable={false}
                onChange={(value) => {
                  if (value) onChange("period")(value);
                }}
                value={state.data.period}
                ranges={[]}
              />
            </InputWrapper>
          </Flex>
          <Modal2ActionButtons
            onClose={onClose}
            onConfirm={handleConfirm}
            isLoading={isLoading}
          />
        </Flex>
      </Modal.Body>
    </>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  onConfirm({
    guestEmail,
    period,
    space,
    numberOfGuests,
  }: {
    guestEmail: string;
    period: [Date, Date];
    space: tHotelSpaceId;
    numberOfGuests: number;
    guestName?: string;
  }): void;
  isLoading: boolean;
  stayId?: tReservationId;
}

const CreateUpdateReservationModal: React.FC<iProps> = ({
  onClose,
  onConfirm,
  isLoading,
  stayId,
  ...props
}) => {
  return (
    <Modal {...{ onClose, keyboard: false, backdrop: "static", ...props }}>
      <CreateUpdateReservationModalWrapped
        {...{ onClose, onConfirm, isLoading, stayId }}
      />
    </Modal>
  );
};

export default CreateUpdateReservationModal;

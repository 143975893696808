import { min } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Table } from "rsuite";
import { ReactComponent as NotesIcon } from "../../../assets/icons/notes.svg";
import { ReactComponent as SquareFootIcon } from "../../../assets/icons/square_foot.svg";
import Flex from "../../../components/Flex";
import ManualDataModal from "../../../components/Modals/ManualDataModal";
import PageSectionTitle from "../../../components/PageSectionTitle";
import SimpleTextCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import InterTag from "../../../components/Text/Inter";
import useHotelManualDataState from "../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { tWithRequired } from "../../../interfaces/others";
import { tHotelManualData } from "../../../models/hotel";
import { COLORS } from "../../../utils/colors";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";

type tTableItem = { label: string; areaM2: number };

interface iAccommodationMeasuresWrappedProps {
  manualDataEntry: tWithRequired<tHotelManualData, "space">;
}

const AccommodationMeasuresWrapped: React.FC<
  iAccommodationMeasuresWrappedProps
> = ({ manualDataEntry }) => {
  const { trans } = useLocalizationState();

  const tableData: tTableItem[] = [
    {
      label: trans(
        "pages.spaces.sections.accommodationMeasures.table.columns.totalArea"
      ),
      areaM2: manualDataEntry!.space!.totalAreaM2,
    },
    {
      label: trans(
        "pages.spaces.sections.accommodationMeasures.table.columns.guestRoomsArea"
      ),
      areaM2: manualDataEntry!.space!.guestRoomsAndCorridorsAreaM2,
    },
    {
      label: trans(
        "pages.spaces.sections.accommodationMeasures.table.columns.meetingRoomsArea"
      ),
      areaM2: manualDataEntry!.space!.meetingRoomsAreaM2,
    },
  ];

  const tableHeight =
    (min([10, tableData.length]) || 1) * TABLE_ROW_HEIGHT.M +
    TABLE_HEADER_HEIGHT;

  return (
    <div className="table-wrapper">
      <Table
        id="TABLE.ACCOMMODATION-MEASURES"
        data={tableData}
        height={tableHeight}
        rowHeight={TABLE_ROW_HEIGHT.M}
        headerHeight={TABLE_HEADER_HEIGHT}
      >
        <Table.Column flexGrow={1}>
          <SimpleHeaderCell
            name={trans(
              "pages.spaces.sections.accommodationMeasures.table.headers.description"
            )}
            icon={NotesIcon}
          />
          <SimpleTextCell dataKey="label" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <SimpleHeaderCell
            name={trans(
              "pages.spaces.sections.accommodationMeasures.table.headers.area"
            )}
            icon={SquareFootIcon}
          />
          <SimpleTextCell
            dataKey="areaM2"
            textProps={() => ({
              asHTML: true,
            })}
            textFunction={(item: tTableItem) => `${item.areaM2} m<sup>2</sup>`}
          />
        </Table.Column>
      </Table>
    </div>
  );
};

interface iProps {}

const AccommodationMeasures: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  const { getManualDataEntryWithDimensionsInTimeframe } =
    useHotelManualDataState();
  const [manualDataModal, setManualDataModal] = useState<{
    open: boolean;
    manualDataId?: tHotelManualData["_id"];
  }>({ open: false, manualDataId: undefined });
  const profilePermissions = useProfilePermissions();
  const manualDataEntry = getManualDataEntryWithDimensionsInTimeframe(moment());

  return (
    <>
      <ManualDataModal
        open={manualDataModal.open}
        onClose={() =>
          setManualDataModal({ open: false, manualDataId: undefined })
        }
        initialStep="space"
        manualDataId={manualDataModal.manualDataId}
      />
      <Flex column gap={16}>
        <PageSectionTitle
          title={trans("pages.spaces.sections.accommodationMeasures.title")}
          description={trans(
            "pages.spaces.sections.accommodationMeasures.description"
          )}
          icon={SquareFootIcon}
        />
        {manualDataEntry && manualDataEntry.space ? (
          <Flex column gap={8}>
            <Flex row gap={12}>
              <InterTag
                size={12}
                color={COLORS.secondary}
                text={trans(
                  "pages.spaces.sections.accommodationMeasures.data_from_date",
                  {
                    parameters: [
                      moment(manualDataEntry.from).format("MMM YYYY"),
                    ],
                  }
                )}
              />
              {profilePermissions.writeManualData && (
                <>
                  <InterTag
                    size={12}
                    hoverUnderline
                    onClick={() =>
                      setManualDataModal({
                        open: true,
                        manualDataId: manualDataEntry._id,
                      })
                    }
                    color={COLORS.primary}
                    text={trans(
                      "pages.spaces.sections.accommodationMeasures.edit_measures"
                    )}
                  />
                  <InterTag
                    size={12}
                    hoverUnderline
                    onClick={() =>
                      setManualDataModal({
                        open: true,
                        manualDataId: undefined,
                      })
                    }
                    color={COLORS.primary}
                    text={trans(
                      "pages.spaces.sections.accommodationMeasures.set_measures"
                    )}
                  />
                </>
              )}
            </Flex>
            <AccommodationMeasuresWrapped
              {...{
                manualDataEntry: manualDataEntry as tWithRequired<
                  tHotelManualData,
                  "space"
                >,
              }}
            />
          </Flex>
        ) : (
          <InterTag
            size={12}
            hoverUnderline
            onClick={() =>
              setManualDataModal({
                open: true,
                manualDataId: undefined,
              })
            }
            color={COLORS.primary}
            text={trans(
              "pages.spaces.sections.accommodationMeasures.set_measures"
            )}
          />
        )}
      </Flex>
    </>
  );
};

export default AccommodationMeasures;

import axios from "axios";
import { find, orderBy, pick, round, sum, zip } from "lodash";
import { MomentInput } from "moment";
import React, { Fragment, useCallback, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import ReloadButton from "../../../components/Buttons/ReloadButton";
import Flex from "../../../components/Flex";
import PageSectionTitle from "../../../components/PageSectionTitle";
import SimpleDateRangePicker from "../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SkeletonText from "../../../components/Skeleton/SkeletonText";
import InterTag from "../../../components/Text/Inter";
import useHotelSettingsState from "../../../context/Hotel/hooks/hotelState/useHotelSettingsState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import usePeriodState from "../../../hooks/usePeriodState";
import { tHotel, tHotelSpaceId } from "../../../models/hotel";
import { TE, tMeasure, tMeasureTotal, TW } from "../../../models/measures";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../utils/convertUnits/";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { get_co2_costs_total_fromMeasures } from "../../../utils/measures";
import { thousandsSeparation } from "../../../utils/numbers";

type tData = {
  bySpace: Record<
    tHotelSpaceId,
    Record<tMeasureTotal | "co2" | "costs", number>
  >;
  grouped: Record<tMeasureTotal | "co2" | "costs", number>;
};

export interface iSpacesConsumptionWrappedProps {}

const SpacesConsumptionWrapped: React.FC<iSpacesConsumptionProps> = () => {
  const { trans } = useLocalizationState();
  const { hotel, isSettingOn } = useHotelSettingsState();
  const [selectedSegment, setSelectedSegment] = useState(0);
  const [period, setPeriod] = usePeriodState();

  const getRequest = useGetRequest<tData>({
    bySpace: {},
    grouped: { costs: 0, co2: 0, te: 0, tw: 0 },
  });

  const run = useCallback(
    (hotel: tHotel, from?: MomentInput, to?: MomentInput) => {
      if (hotel.spaces.length) {
        getRequest.pending();
        axios
          .get(
            `${apiAddress(false)}/v2/hotels/${hotel._id}/spaces/consumption`,
            {
              params: {
                spaces: hotel.spaces.map(({ _id }) => _id),
                from,
                to,
                measures: [TE, TW],
              },
            }
          )
          .then((res) => {
            const { data } = res as {
              data: {
                dataBySpace: Record<
                  tHotelSpaceId,
                  Partial<Record<tMeasure, number[]>>
                >;
              };
            };

            const bySpace: tData["bySpace"] = {};

            Object.entries(data.dataBySpace).forEach((entry) => {
              const [space, measures] = entry as [
                tHotelSpaceId,
                Record<tMeasureTotal, [number]>
              ];
              bySpace[space] = {
                costs: 0,
                co2: 0,
                te: 0,
                tw: 0,
              };
              Object.entries(measures).forEach((entry) => {
                const [key, values] = entry as [tMeasureTotal, [number]];
                bySpace[space][key] = values[0];
              });
              const { co2, costs } = get_co2_costs_total_fromMeasures(hotel, {
                ...pick(data.dataBySpace[space], [TE, TW]),
              });
              bySpace[space].co2 = sum(co2);
              bySpace[space].costs = sum(costs);
            });

            const dataGrouped: any = { te: 0, tw: 0 };
            Object.values(bySpace).forEach(({ te, tw }) => {
              dataGrouped.te += te;
              dataGrouped.tw += tw;
            });

            const { co2, costs } = get_co2_costs_total_fromMeasures(hotel, {
              te: [dataGrouped.te],
              tw: [dataGrouped.tw],
            });
            const grouped: tData["grouped"] = {
              costs: sum(costs),
              co2: sum(co2),
              tw: dataGrouped.tw,
              te: dataGrouped.te,
            };

            getRequest.resolve({ bySpace, grouped });
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            getRequest.reject(error);
          });
      } else {
        getRequest.resolve();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  useEffectSafe(() => {
    if (period) run(hotel, ...period);
    else run(hotel);
  }, [hotel, period, run]);

  if (hotel.spaces.length === 0)
    return (
      <InterTag
        text={trans("No data to show")}
        size={14}
        color={COLORS.secondary}
      />
    );

  const segments: {
    label: string;
    value: number;
    unit: string;
    loading: boolean;
    key: keyof tData["bySpace"][""];
  }[] = (() => {
    const segments: {
      label: string;
      value: number;
      unit: string;
      loading: boolean;
      key: keyof tData["bySpace"][""];
    }[] = [];

    if (isSettingOn("pages.spaces.consumption.co2.show")) {
      const co2 = convertMassUnit(getRequest.data.grouped.co2);
      segments.push({
        key: "co2",
        label: "general.co2_emissions",
        value: round(co2.value, 2),
        unit: co2.unitInHTML,
        loading: !getRequest.isFinal,
      });
    }
    if (isSettingOn("pages.spaces.consumption.electricity.show")) {
      const te = convertEnergyUnit(getRequest.data.grouped.te);
      segments.push({
        key: "te",
        label: "general.measures.electricity",
        value: round(te.value, 2),
        unit: te.unitInHTML,
        loading: !getRequest.isFinal,
      });
    }
    if (isSettingOn("pages.spaces.consumption.water.show")) {
      const tw = convertVolumeUnit(getRequest.data.grouped.tw);
      segments.push({
        key: "tw",
        label: "general.measures.water",
        value: tw.value,
        unit: tw.unitInHTML,
        loading: !getRequest.isFinal,
      });
    }
    if (isSettingOn("pages.spaces.consumption.costs.show")) {
      segments.push({
        key: "costs",
        label: "general.costs",
        value: round(getRequest.data.grouped.costs, 2),
        unit: "€",
        loading: !getRequest.isFinal,
      });
    }

    return segments;
  })();

  const [series, labels] = (() => {
    const selectedKey = segments[selectedSegment].key;

    const mixed: { label: string; value: number }[] = [];
    Object.entries(getRequest.data.bySpace).forEach((entry) => {
      const [spaceId, measures] = entry as [
        tHotelSpaceId,
        tData["bySpace"][""]
      ];
      const space = find(hotel.spaces, (s) => s._id === spaceId);
      if (space)
        mixed.push({ label: space!.name, value: measures[selectedKey] });
    });

    const sorted = orderBy(mixed, ["value"], ["desc"]).map(
      ({ label, value }) => [label, value]
    );
    const [labels, series] = zip(...sorted) as [string[], number[]];

    return [series, labels];
  })();

  return (
    <Flex column className="card-m">
      {getRequest.isRejected ? (
        <Flex row gap={16} middle>
          <ReloadButton
            onClick={() => {
              if (period) run(hotel, ...period);
              else run(hotel);
            }}
          />
          <InterTag size={16} color={COLORS.error} text={getRequest.error} />
        </Flex>
      ) : (
        <Fragment>
          <Flex row right>
            <SimpleDateRangePicker
              value={period}
              onChange={setPeriod}
              shadowOnHover={false}
              darkenOnHover
              placement="left"
              icon={{ fill: COLORS.primary }}
            />
          </Flex>
          <Flex column gap={24}>
            <Flex row gap={16}>
              {segments.map(({ label, loading, unit, value, key }, i) => {
                let labelColor = COLORS.gray_400;
                let textColor = COLORS.secondary;

                if (i === selectedSegment) {
                  labelColor = COLORS.primary;
                  textColor = COLORS.primary;
                }

                return (
                  <Flex
                    key={key}
                    column
                    gap={8}
                    basis={15}
                    style={{
                      cursor: "pointer",
                      padding: "6px",
                      borderRadius: "8px",
                    }}
                    className="hover-darken-white-bg"
                    onClick={() => {
                      if (!getRequest.isLoading)
                        if (i !== selectedSegment) setSelectedSegment(i);
                    }}
                  >
                    <InterTag
                      text={trans(label)}
                      size={12}
                      color={labelColor}
                      asHTML
                    />
                    {loading ? (
                      <SkeletonText width={80} height={36} />
                    ) : (
                      <Flex row gap={4} bottom>
                        <InterTag
                          text={thousandsSeparation(round(value, 2))}
                          size={36}
                          color={textColor}
                        />
                        <InterTag
                          text={unit}
                          size={16}
                          color={textColor}
                          asHTML
                        />
                      </Flex>
                    )}
                  </Flex>
                );
              })}
            </Flex>
            <Flex center>
              {getRequest.isResolved ? (
                <ReactApexChart
                  type="donut"
                  {...{
                    series,
                    options: {
                      theme: {
                        monochrome: {
                          enabled: true,
                          color: COLORS.primary_500,
                          shadeIntensity: 1,
                        },
                      },
                      chart: {
                        type: "donut",
                      },
                      tooltip: {
                        enabled: false,
                      },
                      labels,
                      responsive: [
                        {
                          breakpoint: 10000,
                          options: {
                            chart: {
                              width: 600,
                              height: 300,
                            },
                          },
                        },
                      ],
                      dataLabels: {
                        enabled: false,
                      },
                      legend: {
                        position: "right",
                        fontFamily: "Inter",

                        formatter(legendName, opts) {
                          const name = opts.w.globals.labels[opts.seriesIndex];
                          const val = opts.w.globals.series[opts.seriesIndex];

                          const percentage =
                            (val / sum(opts.w.globals.series)) * 100;

                          return `
                      <span>${name}<span/>
                      <span style="margin-left: 8px">${
                        Number.isNaN(percentage) ? 0 : round(percentage, 1)
                      } %<span/>
                      `;
                        },
                      },
                      stroke: {
                        width: 0,
                      },
                    },
                  }}
                />
              ) : (
                <SkeletonText width={"100%"} height={300} />
              )}
            </Flex>
          </Flex>
        </Fragment>
      )}
    </Flex>
  );
};

export interface iSpacesConsumptionProps {}

const SpacesConsumption: React.FC<iSpacesConsumptionProps> = () => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={trans("pages.spaces.tabs.spaces.consumption.title")}
        description={trans("pages.spaces.tabs.spaces.consumption.description")}
        icon={MeetingRoomIcon}
      />
      <SpacesConsumptionWrapped />
    </Flex>
  );
};

export default SpacesConsumption;

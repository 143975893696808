import { round } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, Whisper } from "rsuite";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/help.svg";
import { ReactComponent as HotelIcon } from "../../../../assets/icons/hotel.svg";
import TrendingComparison from "../../../../components/Comparions/trendingComparison";
import Flex from "../../../../components/Flex";
import Icon from "../../../../components/Icon";
import InterTag from "../../../../components/Text/Inter";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { tWithRequired } from "../../../../interfaces/others";
import { tHotelManualData } from "../../../../models/hotel";
import { COLORS } from "../../../../utils/colors";
import { thousandsSeparation } from "../../../../utils/numbers";

interface iProps {
  manualDataEntry?: tHotelManualData;
}

const OccupancyCard: React.FC<iProps> = ({ manualDataEntry }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { findPreviousAvailableYearManualDataEntry } =
    useHotelManualDataState();

  if (!manualDataEntry) return null;

  const { occupancy } = manualDataEntry;

  if (!occupancy) return null;

  const homologueEntry = findPreviousAvailableYearManualDataEntry(
    manualDataEntry,
    "occupancy"
  );

  const list: {
    key: keyof tWithRequired<tHotelManualData, "occupancy">["occupancy"];
    label: string;
    unit?: string;
  }[] = [
    { key: "numberOfGuests", label: trans("Guests") },
    {
      key: "occupancyRate",
      label: trans("general.occupancy"),
      unit: "%",
    },
  ];

  return (
    <Flex
      style={{ cursor: "pointer" }}
      column
      className="card-m hover-box-shadow"
      gap={20}
      onClick={() => {
        navigate("/occupancy");
      }}
    >
      <Whisper
        speaker={
          <Tooltip>
            {trans("pages.overview.sections.summary.cards.occupancy.tooltip")}
          </Tooltip>
        }
        trigger="hover"
        placement="right"
      >
        <Flex row left middle gap={8} style={{ width: "fit-content" }}>
          <Icon {...{ fill: COLORS.secondary, size: 24, Element: HotelIcon }} />
          <Flex row gap={2}>
            <InterTag
              text={trans("Occupancy")}
              size={20}
              color={COLORS.secondary}
            />
            <Icon Element={HelpIcon} fill={COLORS.secondary} size={12} />
          </Flex>
        </Flex>
      </Whisper>
      <Flex row around>
        {list.map(({ key, label, unit }) => {
          const value = manualDataEntry.occupancy![key];

          return (
            <Flex key={key} column gap={4}>
              <InterTag text={label} size={12} color={COLORS.gray_400} />
              <Flex row gap={4} bottom>
                <InterTag
                  size={36}
                  color={COLORS.secondary}
                  text={thousandsSeparation(round(value, 2))}
                />
                {unit && (
                  <InterTag size={16} color={COLORS.secondary} text={unit} />
                )}
              </Flex>
              {homologueEntry && homologueEntry.occupancy ? (
                <TrendingComparison
                  baseValue={homologueEntry.occupancy![key]}
                  value={value}
                />
              ) : null}
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default OccupancyCard;

import React, { useContext } from "react";
import DataTypeToggle from "../../../components/DataTypeToggle";
import { useDataTypeToggle } from "../../../components/DataTypeToggle/useDataTypeToggle";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import WithContextProvider from "../../../hoc/withProvider";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import ConsumptionContextProvider, {
  ConsumptionContext,
} from "../consumption/context";
import HistoricData from "./historicData";
import LiveData from "./liveData";

const ElectricityGuarded: React.FC = () => {
  const context = useContext(ConsumptionContext);
  const profilePermissions = useProfilePermissions();
  const { activeSubscriptionIsEnterprise } = useHotelSubscriptionState();
  const dataTypeState = useDataTypeToggle();

  if (!activeSubscriptionIsEnterprise)
    return <HistoricData {...{ dataTypeToggle: null, profilePermissions }} />;

  const dataTypeToggle = (
    <DataTypeToggle
      {...{ dataTypeState }}
      loading={
        dataTypeState.isLoading ||
        Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
      }
    />
  );

  if (!dataTypeState.isResolved) return null;

  if (dataTypeState.dataTypeLive) return <LiveData {...{ dataTypeToggle }} />;

  return <HistoricData {...{ dataTypeToggle, profilePermissions }} />;
};

const Electricity: React.FC = () => {
  return (
    <>
      <TopBar
        page="general.measures.electricity"
        parentPages={[{ transKey: "general.consumption", to: "/consumption" }]}
      />
      <IpValidatorGuard>
        <ElectricityGuarded />
      </IpValidatorGuard>
    </>
  );
};

export default WithContextProvider(ConsumptionContextProvider, Electricity);

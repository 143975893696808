import React, { PropsWithChildren } from "react";
import { ReactComponent as NoytrallSymbol } from "../../../assets/noytrall_symbol.svg";
import { ReactComponent as NoytrallWord } from "../../../assets/noytrall_word.svg";
import { COLORS } from "../../../utils/colors";
import Flex from "../../Flex";
import Icon from "../../Icon";

const NoAuthMobileWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex className="height-100" color={COLORS.light_white} column>
      <Flex row gap={10} center middle style={{ marginTop: "24px" }}>
        <Icon Element={NoytrallWord} width={100} fill={COLORS.secondary} />
        <Icon Element={NoytrallSymbol} height={50} />
      </Flex>
      {children}
    </Flex>
  );
};

export default NoAuthMobileWrapper;

import { find } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useRef } from "react";
import { DateRangePicker, DateRangePickerProps } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { ReactComponent as DateRangeIcon } from "../../../assets/icons/date_range.svg";
import { ReactComponent as KeyboardArrowDownIcon } from "../../../assets/icons/keyboard_arrow_down.svg";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../context/WindowSize/hooks/useWindowSizeState";
import { tIcon } from "../../../interfaces/icon";
import { COLORS } from "../../../utils/colors";
import {
  DATE_RANGE_PICKER_RANGES,
  tDateRangeListItem,
} from "../../../utils/rsuite/dateRange";
import Flex from "../../Flex";
import Icon from "../../Icon";
import InterTag from "../../Text/Inter";

interface iProps
  extends Omit<DateRangePickerProps, "value" | "onChange" | "ranges"> {
  value: [MomentInput, MomentInput];
  shadowOnHover?: boolean;
  darkenOnHover?: boolean;
  icon?: Partial<tIcon>;
  onChange?(arg: DateRange): void;
  ranges?: tDateRangeListItem;
}

const SimpleDateRangePicker: React.FC<iProps> = ({
  value,
  onChange,
  style,
  shadowOnHover = true,
  darkenOnHover = false,
  icon,
  disabled,
  ranges = DATE_RANGE_PICKER_RANGES,
  ...rest
}) => {
  const { isMobile } = useWindowSizeState();

  const [textSize, iconSize, containerHeight] = isMobile
    ? [10, 14, 30]
    : [14, 20, 34];

  const { trans } = useLocalizationState();
  const ref = useRef<any>();
  let text = "";
  if (ranges) {
    if (value) {
      const range = find(
        ranges,
        (r: any) =>
          moment(r.value[0]).valueOf() === moment(value[0]).valueOf() &&
          moment(r.value[1]).valueOf() === moment(value[1]).valueOf()
      );

      if (range) text += `${trans(range.label)} - `;

      const formatter = (date: MomentInput) => {
        return moment(date).format("MMM DD, YYYY");
      };

      text += `${formatter(value[0])} - ${formatter(value[1])}`;
    }
  }

  return (
    <Flex style={{ position: "relative" }}>
      <DateRangePicker
        ref={ref}
        ranges={ranges}
        cleanable={false}
        character="   -   "
        onChange={(range) => range && onChange && onChange(range)}
        style={{
          width: 0,
          ...(disabled ? {} : { cursor: "pointer" }),
          visibility: "hidden",
          height: 0,
          position: "absolute",
        }}
        size="md"
        value={value.map((v) => moment(v).toDate()) as [Date, Date]}
        position="bottomEnd"
        {...rest}
      />
      <Flex
        color={disabled ? COLORS.gray_100 : COLORS.white}
        onClick={() => {
          if (!disabled) ref.current.open();
        }}
        style={{
          ...(disabled ? {} : { cursor: "pointer" }),
          padding: "0 8px",
          height: `${containerHeight}px`,
          borderRadius: "8px",
          ...style,
        }}
        row
        middle
        className={
          disabled
            ? ""
            : `${shadowOnHover ? "hover-box-shadow" : ""} ${
                darkenOnHover ? "hover-darken-white-bg" : ""
              }`
        }
        gap={6}
      >
        <Icon
          {...{
            Element: DateRangeIcon,
            size: iconSize,
            fill: disabled ? COLORS.gray_600 : COLORS.secondary,
            ...icon,
          }}
        />
        <InterTag
          text={text}
          size={textSize}
          color={disabled ? COLORS.gray_600 : COLORS.secondary}
        />
        {!disabled && (
          <Icon
            Element={KeyboardArrowDownIcon}
            size={iconSize}
            fill={disabled ? COLORS.gray_600 : COLORS.secondary}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default SimpleDateRangePicker;

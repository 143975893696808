import axios from "axios";
import {
  cloneDeep,
  findIndex,
  isUndefined,
  sortBy,
  uniq,
  uniqueId,
} from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import {
  Checkbox,
  IconButton,
  Input,
  InputGroup,
  InputNumber,
  Modal,
  ModalProps,
} from "rsuite";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import { ReactComponent as MoreVertIcon } from "../../../assets/icons/more_vert.svg";
import Modal2ActionButtons from "../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import PageSectionTitle from "../../../components/PageSectionTitle";
import SimpleTagPicker from "../../../components/RsuiteWrapper/SimpleTagPicker";
import SimpleWhisperPopoverDropdown, {
  tSimpleWhisperPopoverDropdownOptions,
} from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../components/Text/Inter";
import useHotelSpacesState from "../../../context/Hotel/hooks/hotelState/useHotelSpacesState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePutRequest, {
  tUsePutRequest,
} from "../../../hooks/apiRequests/usePutRequest";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { tHotelSpaceAggregateId, tHotelSpaceId } from "../../../models/hotel";
import {
  AC,
  CW,
  DW,
  EL,
  ES,
  GW,
  HW,
  MEASURES,
  NTW,
  TE,
  tMeasure,
  TW,
  WH,
} from "../../../models/measures";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { getMeasureInfo } from "../../../utils/measures";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";

const buildTransKey = (keyword: string) =>
  `pages.spaces.modals.space.${keyword}`;

type tData = {
  _id: string;
  name: string;
  groups: tHotelSpaceAggregateId[];
  zones: tHotelSpaceAggregateId[];
  types: tHotelSpaceAggregateId[];
  areaInSquareMeters: string;
  isAccommodation: boolean;
  measures: tMeasure[];
};

type tErrors = Record<keyof tData, string>;

const initStateData = (name: string): tData => ({
  _id: uniqueId(),
  name,
  groups: [],
  zones: [],
  types: [],
  areaInSquareMeters: "",
  measures: [],
  isAccommodation: false,
});
const initStateErrors = (): tErrors => ({
  _id: "",
  name: "",
  groups: "",
  zones: "",
  types: "",
  areaInSquareMeters: "",
  measures: "",
  isAccommodation: "",
});

interface iSpaceModalWrappedProps {
  confirmRequest: tUsePutRequest;
  onClose(): void;
  spaceId?: tHotelSpaceId;
  spaceName?: string;
}

const SpaceModalWrapped: React.FC<iSpaceModalWrappedProps> = ({
  confirmRequest,
  onClose,
  spaceId,
  spaceName,
}) => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const {
    hotelId,
    hotel: { spaces },
    findSpace,
    findSpaceSpaceAggregates,
    spaceAggregatesByCategory,
  } = useHotelSpacesState();
  const { updateHotel, updatingHotel } = useHotelDispatch();
  const [state2, setState2] = useState<{
    selected: number;
    items: { data: tData; errors: tErrors }[];
  }>(() => {
    if (spaceId) {
      const space = findSpace(spaceId);
      if (space) {
        return {
          selected: 0,
          items: [
            {
              data: {
                _id: space._id,
                areaInSquareMeters: `${space.areaInSquareMeters || ""}`,
                isAccommodation: space.isAccommodation,
                measures: cloneDeep(
                  MEASURES.filter(
                    (measure) => space.measures[measure].isMeasured
                  )
                ) as tMeasure[],
                name: space.name,
                groups: findSpaceSpaceAggregates(spaceId, "group").map(
                  (g) => g._id
                ),
                zones: findSpaceSpaceAggregates(spaceId, "zone").map(
                  (g) => g._id
                ),
                types: findSpaceSpaceAggregates(spaceId, "type").map(
                  (g) => g._id
                ),
              },
              errors: initStateErrors(),
            },
          ],
        };
      }
    }

    return {
      selected: 0,
      items: [
        {
          data: initStateData(`${trans("general.name")} #${uniqueId()}`),
          errors: initStateErrors(),
        },
      ],
    };
  });

  const onChange =
    (
      key: keyof Pick<
        tData,
        "name" | "areaInSquareMeters" | "groups" | "types" | "zones"
      >
    ) =>
    (value: any) => {
      setState2((prev) => {
        const data = prev.items[prev.selected].data;
        data[key] = value;
        return { ...prev };
      });
    };

  const { data: activeSpace, errors: activeErrors } =
    state2.items[state2.selected];

  useEffect(() => {
    if (confirmRequest.isIdle) {
      if (activeSpace.name !== spaceName) {
        if (spaces.map((s) => s.name).includes(activeSpace.name)) {
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeSpace.name,
    confirmRequest.isIdle,
    spaceName,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(sortBy(spaces.map((s) => s.name))),
  ]);

  const setMeasures = (newMeasures: tMeasure[]) => {
    setState2((prev) => {
      const item = prev.items[prev.selected];
      item.data.measures = newMeasures;

      return { ...prev };
    });
  };

  const onClickBeingMeasuredCheckbox = (key: tMeasure, parentKey: tMeasure) => {
    const index = findIndex(activeSpace.measures, (v) => v === key);
    if (index === -1)
      return setMeasures(uniq([...activeSpace.measures, key, parentKey]));
    return setMeasures(activeSpace.measures.filter((v, i) => i !== index));
  };

  const requestPending = confirmRequest.isLoading;

  const renderCheckPickerMeasures = () => {
    const list: {
      key: tMeasure;
      label: string;
      children: { key: tMeasure; label: string }[];
    }[] = [
      {
        key: TE,
        label: getMeasureInfo(TE, trans).label,
        children: [
          { key: ES, label: getMeasureInfo(ES, trans).label },
          { key: EL, label: getMeasureInfo(EL, trans).label },
          { key: AC, label: getMeasureInfo(AC, trans).label },
          { key: WH, label: getMeasureInfo(WH, trans).label },
        ],
      },
      {
        key: TW,
        label: getMeasureInfo(TW, trans).label,
        children: [
          { key: HW, label: getMeasureInfo(HW, trans).label },
          { key: GW, label: getMeasureInfo(GW, trans).label },
          { key: CW, label: getMeasureInfo(CW, trans).label },
          { key: DW, label: getMeasureInfo(DW, trans).label },
          { key: NTW, label: getMeasureInfo(NTW, trans).label },
        ],
      },
    ];

    return list.flatMap(({ key: parentKey, label, children }) => {
      const checked = activeSpace.measures.includes(parentKey);

      return [
        <Checkbox
          disabled={requestPending}
          checked={checked}
          key={parentKey}
          onChange={() => {
            const measureKeysToRemove = [
              parentKey,
              ...children.map((c) => c.key),
            ];
            if (checked)
              setMeasures(
                activeSpace.measures.filter(
                  (measure) => !measureKeysToRemove.includes(measure)
                )
              );
            else setMeasures(uniq([...activeSpace.measures, parentKey]));
          }}
        >
          {label}
        </Checkbox>,
        <Flex
          column
          key={`${parentKey}:children`}
          style={{ marginLeft: "32px" }}
        >
          {children.map(({ key: childKey, label }) => {
            return (
              <Checkbox
                disabled={requestPending}
                key={childKey}
                onChange={() =>
                  onClickBeingMeasuredCheckbox(childKey as tMeasure, parentKey)
                }
                checked={activeSpace.measures.includes(childKey)}
              >
                {label}
              </Checkbox>
            );
          })}
        </Flex>,
      ];
    });
  };

  const handleConfirm = () => {
    updatingHotel();
    confirmRequest.pending();

    (spaceId
      ? axios.patch(
          `${apiAddress(true)}/v2/hotels/${hotelId}/spaces/${spaceId}`,
          {
            name: activeSpace.name,
            areaInSquareMeters: activeSpace.areaInSquareMeters,
            isAccommodation: activeSpace.isAccommodation,
            spaceAggregates: [
              ...activeSpace.groups,
              ...activeSpace.types,
              ...activeSpace.zones,
            ],
            measures: activeSpace.measures,
          }
        )
      : axios.post(`${apiAddress(true)}/v2/hotels/${hotelId}/spaces`, {
          items: state2.items.map(({ data }) => ({
            name: data.name,
            areaInSquareMeters: data.areaInSquareMeters,
            isAccommodation: data.isAccommodation,
            spaceAggregates: [...data.groups, ...data.types, ...data.zones],
            measures: data.measures,
          })),
        })
    )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        if (spaceId) {
          toaster.success(
            trans("general.updated_x", { parameters: [trans("general.space")] })
          );
        } else {
          if (state2.items.length === 1) {
            toaster.success(
              trans("general.created_x", {
                parameters: [trans("general.space")],
              })
            );
          } else
            toaster.success(
              trans("general.created_multiple_x", {
                parameters: [trans("general.spaces")],
              })
            );
        }
        confirmRequest.resolve();
        updateHotel(hotelId, hotel);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        try {
          const {
            data: { hotel },
          } = err.response;
          const error = getErrorMessage(err, trans);
          updateHotel(hotelId, hotel);
          confirmRequest.reject(error, true);
        } catch (err) {}
        confirmRequest.reject("");
      });
  };

  const renderTabs = () => {
    const newTab = () => {
      if (state2.items.length === 20) {
        toaster.error(
          trans(buildTransKey("notifications.max_number_of_items"))
        );
        return;
      }
      setState2((prev) => ({
        ...prev,
        items: [
          ...prev.items,
          {
            data: initStateData(`${trans("general.name")} #${uniqueId()}`),
            errors: initStateErrors(),
          },
        ],
        selected: prev.items.length,
      }));
    };

    const options: tSimpleWhisperPopoverDropdownOptions[] = [
      {
        key: "remove-all-but-1",
        label: trans("components.tab.options.remove_all_but_1"),
        onClick: () => {
          setState2((prev) => {
            const item = prev.items[prev.selected];
            return { ...prev, items: [item], selected: 0 };
          });
        },
        show: state2.items.length > 1,
      },
    ];
    return (
      <div
        style={{ width: "100%", borderBottom: `${COLORS.gray_200} 1px solid` }}
        className={"horizontal-scrollbar"}
      >
        <Flex row>
          <Flex style={{ overflow: "auto", maxWidth: "90%" }} row>
            {state2.items.map((item, i) => {
              return (
                <Flex
                  middle
                  center
                  row
                  color={
                    state2.selected === i ? COLORS.gray_200 : COLORS.gray_50
                  }
                  gap={4}
                  key={item.data._id}
                  onClick={() => {
                    setState2((prev) => ({ ...prev, selected: i }));
                  }}
                  size="xs"
                  className="hover-gray-200"
                  style={{
                    padding: "4px 8px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <InterTag
                    text={trans(item.data.name)}
                    size={12}
                    color={COLORS.secondary}
                  />

                  {state2.items.length > 1 && (
                    <IconButton
                      className="hover-gray-300"
                      onClick={(e) => {
                        e.stopPropagation();
                        setState2((prev) => {
                          const items = prev.items;
                          items.splice(i, 1);
                          return {
                            ...prev,
                            items: [...items],
                            selected:
                              prev.selected >= items.length
                                ? prev.selected - 1
                                : prev.selected,
                          };
                        });
                      }}
                      style={{ padding: "3px" }}
                      circle
                      size="xs"
                      appearance="subtle"
                      icon={
                        <Icon
                          Element={CloseIcon}
                          fill={COLORS.secondary}
                          size={12}
                        />
                      }
                    />
                  )}
                </Flex>
              );
            })}
          </Flex>
          <Flex middle center row gap={4} style={{ width: "10%" }}>
            <IconButton
              size="xs"
              icon={
                <Icon Element={AddIcon} fill={COLORS.secondary} size={16} />
              }
              onClick={newTab}
            />
            {options.filter((o) => o.show).length > 0 && (
              <SimpleWhisperPopoverDropdown options={options}>
                <IconButton
                  size="xs"
                  icon={
                    <Icon
                      Element={MoreVertIcon}
                      fill={COLORS.secondary}
                      size={16}
                    />
                  }
                />
              </SimpleWhisperPopoverDropdown>
            )}
          </Flex>
        </Flex>
      </div>
    );
  };

  const confirmButtonDisabled =
    Object.values(activeErrors).filter((v) => v.length).length > 0 ||
    activeSpace.name.length === 0;

  return (
    <Fragment>
      <Modal.Header closeButton={!confirmRequest.isLoading}></Modal.Header>
      <Modal.Body className="modal-body-pb0">
        <Flex column gap={20}>
          <PageSectionTitle
            title={trans(spaceId ? "general.update_x" : "general.new_x", {
              parameters: [trans("general.space")],
            })}
            icon={{ Element: MeetingRoomIcon }}
          />
          {isUndefined(spaceId) && (
            <Flex column gap={8}>
              {renderTabs()}
              <Flex column gap={4}></Flex>
            </Flex>
          )}
          <Flex row gap={60}>
            <Flex column gap={8} basis={50}>
              <InputWrapper
                label={{ text: trans("general.name") }}
                error={{ text: activeErrors.name }}
              >
                <Input
                  type="text"
                  size="md"
                  disabled={requestPending}
                  value={activeSpace.name}
                  onChange={onChange("name")}
                  placeholder={trans(buildTransKey("inputs.name.placeholder"))}
                />
              </InputWrapper>
              <InputWrapper
                label={{ text: trans("general.area") }}
                error={{ text: activeErrors.areaInSquareMeters }}
              >
                <InputGroup>
                  <InputNumber
                    disabled={requestPending}
                    min={0}
                    value={activeSpace.areaInSquareMeters}
                    placeholder={"123"}
                    onChange={onChange("areaInSquareMeters")}
                  />
                  <InputGroup.Addon>
                    m<sup>2</sup>
                  </InputGroup.Addon>
                </InputGroup>
              </InputWrapper>
              <InputWrapper
                label={{
                  text: trans("general.space_categories_.extended.groups"),
                }}
                error={{ text: activeErrors.groups }}
              >
                <SimpleTagPicker
                  disabled={requestPending}
                  onChange={onChange("groups")}
                  value={activeSpace.groups}
                  data={spaceAggregatesByCategory["group"].map(
                    ({ name, _id }) => ({
                      value: _id,
                      label: name,
                    })
                  )}
                />
              </InputWrapper>
              <InputWrapper
                label={{
                  text: trans("general.space_categories_.extended.types"),
                }}
                error={{ text: activeErrors.types }}
              >
                <SimpleTagPicker
                  disabled={requestPending}
                  onChange={onChange("types")}
                  value={activeSpace.types}
                  data={spaceAggregatesByCategory["type"].map(
                    ({ name, _id }) => ({
                      value: _id,
                      label: name,
                    })
                  )}
                />
              </InputWrapper>
              <InputWrapper
                label={{
                  text: trans("general.space_categories_.extended.zones"),
                }}
                error={{ text: activeErrors.zones }}
              >
                <SimpleTagPicker
                  disabled={requestPending}
                  onChange={onChange("zones")}
                  value={activeSpace.zones}
                  data={spaceAggregatesByCategory["zone"].map(
                    ({ name, _id }) => ({
                      value: _id,
                      label: name,
                    })
                  )}
                />
              </InputWrapper>

              <Checkbox
                disabled={requestPending}
                checked={activeSpace.isAccommodation}
                onChange={(value, checked) => {
                  setState2((prev) => {
                    prev.items[prev.selected].data.isAccommodation = checked;
                    return { ...prev };
                  });
                }}
              >
                {trans("pages.spaces.modals.space.is_accommodation")}
              </Checkbox>
            </Flex>
            <Flex column gap={8}>
              <Flex column>{renderCheckPickerMeasures()}</Flex>
            </Flex>
          </Flex>
          <Modal2ActionButtons
            onClose={onClose}
            onConfirm={handleConfirm}
            isLoading={requestPending}
            confirmButtonDisabled={confirmButtonDisabled}
          />
        </Flex>
      </Modal.Body>
    </Fragment>
  );
};

interface iSpaceModalProps extends ModalProps {
  onClose(): void;
  spaceId?: string;
  spaceName?: string;
}

const SpaceModal: React.FC<iSpaceModalProps> = ({
  spaceId,
  spaceName,
  onClose,
  ...props
}) => {
  const confirmRequest = usePutRequest();

  return (
    <Modal
      id="space-modal"
      size="md"
      {...{
        ...DEFAULT_MODAL_PROPS,
        ...props,
        overflow: true,
        onClose,
      }}
    >
      <SpaceModalWrapped
        {...{
          confirmRequest,
          onClose,
          spaceId,
          spaceName,
        }}
      />
    </Modal>
  );
};

export default SpaceModal;

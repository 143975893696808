import axios from "axios";
import { isUndefined } from "lodash";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import { ReactComponent as PersonIcon } from "../../../assets/icons/person.svg";
import ReloadButton from "../../../components/Buttons/ReloadButton";
import Flex from "../../../components/Flex";
import PageBottomPadding from "../../../components/PageBottomPadding";
import PageSectionTitle from "../../../components/PageSectionTitle";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest, {
  tUseGetRequest,
} from "../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import useScreenSize from "../../../hooks/useScreenSize";
import { tGuest } from "../../../models/guest";
import { tHotelId } from "../../../models/hotel";
import { tReservation } from "../../../models/reservation";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getCountryFlagURL2, getCountryInfo } from "../../../utils/countries";
import { defaultGuest } from "../../../utils/guests";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import ActiveStays from "./activeStays";
import AverageGlobalConsumption from "./averageGlobalConsumption";
import Awards from "./awards";
import PreviousStays from "./previousStays";

export type tGuestRequestData = { guest: tGuest; noDataOnGuest: boolean };
export type tGuestRequest = tUseGetRequest<tGuestRequestData>;

export type tGuestReservations = {
  reservations: {
    past: tReservation[];
    ongoing: tReservation[];
    upcoming: tReservation[];
  };
};

const Guest: React.FC = () => {
  const { outlet } = useScreenSize();
  const location = useLocation();
  const params = useParams<{ idName: string }>();
  const { trans } = useLocalizationState();
  const { hotelId } = useHotelState();

  const [reservation] = useState(location.state.reservation);
  const guestRequest = useGetRequest<tGuestRequestData>({
    guest: { ...defaultGuest },
    noDataOnGuest: false,
  });

  const guestReservationsRequest = useGetRequest<tGuestReservations>({
    reservations: { ongoing: [], upcoming: [], past: [] },
  });

  const [requestInbound, setRequestInbound] = useState(false);

  const run = useCallback(
    (hotelId: tHotelId, guestIdName: string, reservation?: tReservation) => {
      if (reservation) {
        if (reservation.mainGuest._id) {
          setRequestInbound(true);
          guestRequest.pending();
          axios
            .get(`${apiAddress(false)}/v2/guests/${reservation.mainGuest._id}`)
            .then((res) => {
              const {
                data: { guest },
              } = res;
              guestRequest.resolve({ guest, noDataOnGuest: false });
            })
            .catch((err) => {
              const error = getErrorMessage(err, trans);
              guestRequest.reject(error);
            });
        } else if (reservation.mainGuest.name) {
          setRequestInbound(true);
          guestRequest.resolve({
            guest: { ...defaultGuest, name: reservation.mainGuest.name },
            noDataOnGuest: true,
          });
        }
      } else {
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  useEffectSafe(() => {
    if (!requestInbound && params.idName) {
      run(hotelId, params.idName, reservation);
    }
  }, [hotelId, params.idName, reservation, requestInbound, run]);

  useEffectSafe(() => {
    if (hotelId && guestRequest.isResolved) {
      guestReservationsRequest.pending();
      axios
        .get(
          `${apiAddress(false)}/v2/hotels/${hotelId}/guests/${
            guestRequest.data.guest._id
          }/stays`
        )
        .then((res) => {
          const {
            data: { reservations },
          } = res;

          const past: tReservation[] = [];
          const ongoing: tReservation[] = [];
          const upcoming: tReservation[] = [];

          reservations.forEach((r: tReservation) => {
            if (moment(r.startAt).isAfter(moment())) upcoming.push(r);
            else if (moment(r.endAt).isAfter(moment())) ongoing.push(r);
            else past.push(r);
          });

          guestReservationsRequest.resolve({
            reservations: { past, ongoing, upcoming },
          });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          guestReservationsRequest.reject(error);
        });
    }
  }, [guestRequest.isResolved, hotelId, trans]);

  if (!outlet) return null;

  const topBarDisplayText =
    (guestRequest.isResolved ? guestRequest.data.guest.name : params.idName) ||
    "";

  const renderGuestInfo = () => {
    return (
      <Flex gap={16} column>
        <PageSectionTitle
          title={trans("Guest Information")}
          description={trans("A snapshot of guest details.")}
          icon={PersonIcon}
        />
        {guestRequest.isLoading ? (
          <Flex middle center>
            <Loader size={"md"} />
          </Flex>
        ) : guestRequest.isResolved ? (
          (() => {
            const { name, email, country } = guestRequest.data.guest;

            const countryInfo = getCountryInfo(country);

            return (
              <Flex row>
                <Flex basis={50} column className={"card-m"} gap={16}>
                  <Flex column gap={8}>
                    <InterTag size={36} color={COLORS.secondary} text={name} />
                    <InterTag size={16} color={COLORS.primary} text={email} />
                  </Flex>
                  {!isUndefined(countryInfo) && (
                    <Flex row gap={4}>
                      <img
                        src={getCountryFlagURL2(countryInfo.iso.alpha2)}
                        alt="country"
                        style={{ width: "25px" }}
                      />
                      <InterTag
                        text={countryInfo.name}
                        size={16}
                        color={COLORS.secondary}
                      />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            );
          })()
        ) : (
          <Flex row gap={8} middle>
            <ReloadButton
              onClick={() => {
                if (hotelId && params.idName)
                  run(hotelId, params.idName, reservation);
              }}
            />
            <InterTag
              text={trans("Error loading guest info")}
              color={COLORS.error}
              size={14}
            />
          </Flex>
        )}
      </Flex>
    );
  };

  return (
    <>
      <TopBar
        page={topBarDisplayText}
        parentPages={[{ transKey: "general.guests", to: "/guests" }]}
      />
      <Flex column gap={40}>
        {renderGuestInfo()}
        {guestRequest.data.noDataOnGuest === false && (
          <>
            <AverageGlobalConsumption {...{ guestRequest }} />
            <ActiveStays staysRequest={guestReservationsRequest} />
            <PreviousStays
              staysRequest={guestReservationsRequest}
              guestRequest={guestRequest}
            />
            <Awards staysRequest={guestReservationsRequest} />
          </>
        )}
      </Flex>
      <PageBottomPadding />
    </>
  );
};

export default Guest;

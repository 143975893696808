import { isFunction } from "lodash";
import React from "react";
import { NavLink } from "react-router-dom";
import Flex from "../../../../components/Flex";
import Icon from "../../../../components/Icon";
import InterTag from "../../../../components/Text/Inter";
import useHotelsListState from "../../../../context/Hotel/hooks/hotelState/useHotelsListState";
import useHotelState from "../../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../../context/WindowSize/hooks/useWindowSizeState";
import { tIconElement } from "../../../../interfaces/icon";
import { COLORS } from "../../../../utils/colors";
import { MENU_MAP } from "../../../../utils/pages";

const hideAvailablePropertiesPage = (hotels: any[]) => hotels.length <= 1;

type tMenuElement = {
  key: string;
  icon: tIconElement;
  transKey: string;
  to: string;
  index?: boolean;
  hide?(hotels: any[]): boolean;
};

const LIST: tMenuElement[][] = [
  [
    {
      key: "properties",
      ...MENU_MAP.properties,
      hide: hideAvailablePropertiesPage,
    },
  ],
  [
    {
      key: "overview",
      ...MENU_MAP.overview,
    },
    {
      key: "sustainability",
      ...MENU_MAP.sustainability,
    },
  ],
  [
    {
      key: "spaces",
      ...MENU_MAP["spaces"],
    },

    {
      key: "guests",
      ...MENU_MAP["guests"],
    },
    {
      key: "occupancy",
      ...MENU_MAP["occupancy"],
    },
  ],
  [
    {
      key: "consumption",
      ...MENU_MAP["consumption"],
    },
    {
      key: "consumption.electricity",
      ...MENU_MAP["consumption.electricity"],
    },
    {
      key: "consumption.water",
      ...MENU_MAP["consumption.water"],
    },
    {
      key: "consumption.fuels",
      ...MENU_MAP["consumption.fuels"],
    },
    {
      key: "consumption.waste",
      ...MENU_MAP["consumption.waste"],
    },
    {
      key: "consumption.laundry",
      ...MENU_MAP["consumption.laundry"],
    },
    {
      key: "consumption.fleet",
      ...MENU_MAP["consumption.fleet"],
    },
  ],
  [
    {
      key: "awards",
      ...MENU_MAP["awards"],
    },
    {
      key: "challenges",
      ...MENU_MAP["challenges"],
    },
    {
      key: "loyalty",
      ...MENU_MAP["loyalty"],
    },
  ],
  [
    {
      key: "users",
      ...MENU_MAP["users"],
    },
    {
      key: "profile",
      ...MENU_MAP["profile"],
    },
  ],
];

const MorePages: React.FC = () => {
  const { isLaptop } = useWindowSizeState();
  const { trans } = useLocalizationState();
  const { hotel } = useHotelState();
  const { hotels } = useHotelsListState();

  if (isLaptop) {
    return null;
  }

  const smallDivider = (
    <div
      style={{
        height: "1px",
        width: "calc(100% - 64px)",
        marginLeft: "64px",
        backgroundColor: COLORS.gray_400,
      }}
    />
  );

  const bigDivider = (
    <div
      style={{
        height: "20px",
        width: "100%",
        backgroundColor: COLORS.gray_400,
      }}
    />
  );

  const items = LIST.map((c: any[]) =>
    c.filter((e) => (isFunction(e.hide) ? !e.hide(hotels) : true))
  )
    .filter((c) => c.length)
    .flatMap((group) => [
      group
        .flatMap(({ icon, key, to, transKey }) => [
          <NavLink key={key} to={`/${to}`}>
            <Flex row middle gap={16} style={{ padding: "16px 16px" }}>
              <Icon Element={icon} size={32} fill={COLORS.secondary} />
              <InterTag
                size={20}
                bold
                text={trans(transKey)}
                color={COLORS.secondary}
              />
            </Flex>
          </NavLink>,
          smallDivider,
        ])
        .slice(0, -1),
      bigDivider,
    ])
    .slice(0, -1);

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          height: "70px",
          backgroundColor: COLORS.secondary,
          zIndex: 3,
        }}
      >
        <Flex row style={{ height: "100%", padding: "0 8px" }}>
          <Flex center middle>
            <InterTag text={hotel.name} color={COLORS.white} size={20} />
          </Flex>
        </Flex>
      </div>
      <Flex column gap={70}>
        <Flex column color={COLORS.app_background} style={{ height: "100%" }}>
          <Flex column style={{ marginTop: "80px" }}>
            {items.map((item) => item)}
          </Flex>
        </Flex>
        <div />
      </Flex>
    </div>
  );
};

export default MorePages;

import React, { useContext, useEffect, useState } from "react";
import Flex from "../../../../components/Flex";
import HistoricDataConsumptionGraphSection from "../../../../components/HistoricDataConsumptionGraphSection";
import ManualDataModal from "../../../../components/Modals/ManualDataModal";
import Roadmap from "../../../../components/RoadmapTable";
import SimpleButton from "../../../../components/RsuiteWrapper/SimpleButton";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import { tProfilePermissions } from "../../../../hooks/useProfilePermissions";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { ConsumptionContext } from "../../consumption/context";
import Showcasing from "./showcasing";

interface iProps {
  dataTypeToggle: JSX.Element | null;
  profilePermissions: tProfilePermissions;
}

const HistoricData: React.FC<iProps> = ({
  dataTypeToggle,
  profilePermissions,
}) => {
  const { trans } = useLocalizationState();
  const [manualDataModal, setManualDataModal] = useState({ open: false });
  const { setDataTypeToggleDisabled } = useContext(ConsumptionContext);
  const { measuresTrackedInManualData } = useHotelManualDataState();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: measuresTrackedInManualData.electricity,
    initialStatus: measuresTrackedInManualData.water
      ? REQUEST_STATUS.PENDING
      : REQUEST_STATUS.IDLE,
  });

  useEffect(() => {
    setDataTypeToggleDisabled({
      conversionFactorsRequest: conversionFactorsRequest.isLoading,
    });
  }, [conversionFactorsRequest.isLoading, setDataTypeToggleDisabled]);

  return (
    <>
      {profilePermissions.writeManualData && (
        <ManualDataModal
          open={manualDataModal.open}
          initialStep="electricity"
          singleStep
          onClose={() => setManualDataModal({ open: false })}
        />
      )}
      <div>
        <SecondaryTopBar>
          <Flex row middle gap={8}>
            {dataTypeToggle}
            {profilePermissions.writeManualData && (
              <SimpleButton
                appearance="primary"
                text={{
                  text: trans("components.manual_data.global_add_entry.label"),
                  color: COLORS.white,
                  size: 12,
                }}
                onClick={() => setManualDataModal({ open: true })}
              />
            )}
          </Flex>
        </SecondaryTopBar>
        <Flex column gap={40}>
          <HistoricDataConsumptionGraphSection
            {...{ conversionFactorsRequest, measure: "electricity" }}
          />
          <Showcasing />
          <Roadmap filterByType="electricity" />
        </Flex>
      </div>
    </>
  );
};

export default HistoricData;

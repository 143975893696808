import React, { Fragment, useContext } from "react";
import DataTypeToggle from "../../../components/DataTypeToggle";
import { useDataTypeToggle } from "../../../components/DataTypeToggle/useDataTypeToggle";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import WithContextProvider from "../../../hoc/withProvider";
import SustainabilityContextProvider, {
  SustainabilityContext,
} from "./context";
import ManualData from "./manualData";
import SensorData from "./sensorData";

interface iSustainabilityWrappedProps {}

const SustainabilityWrapped: React.FC<iSustainabilityWrappedProps> = () => {
  const { activeSubscriptionIsEnterprise } = useHotelSubscriptionState();
  const context = useContext(SustainabilityContext);
  const dataTypeState = useDataTypeToggle();

  if (!activeSubscriptionIsEnterprise)
    return <ManualData dataTypeToggle={null} />;

  const toggle = (
    <DataTypeToggle
      {...{ dataTypeState }}
      loading={
        dataTypeState.isLoading ||
        Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
      }
    />
  );

  if (!dataTypeState.isResolved) return null;

  if (dataTypeState.dataTypeLive) return <SensorData dataTypeToggle={toggle} />;
  return <ManualData dataTypeToggle={toggle} />;
};

const Sustainability: React.FC = () => {
  return (
    <>
      <TopBar page={"general.sustainability"} />
      <IpValidatorGuard>
        <Fragment>
          <SustainabilityWrapped />
          <PageBottomPadding />
        </Fragment>
      </IpValidatorGuard>
    </>
  );
};

export default WithContextProvider(
  SustainabilityContextProvider,
  Sustainability
);

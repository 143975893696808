import { at } from "lodash";
import React from "react";
import { HotelStateContext } from "../../";
import useHotelState from "./useHotelState";

const useHotelSettingsState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelSettingsState must be used within HotelStateContext");

  const { hotel, hotelId } = useHotelState();

  const isSettingOn = (path: string) => {
    const setting = at(hotel.settings, path);
    return setting[0] === true;
  };

  return {
    hotel,
    hotelId,
    isSettingOn,
  };
};

export default useHotelSettingsState;

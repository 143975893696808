import axios from "axios";
import { at, round, sum } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";
import { ReactComponent as HotelIcon } from "../../../assets/icons/hotel.svg";
import Flex from "../../../components/Flex";
import ApexChart from "../../../components/Graphs/Apex/Chart";
import PageSectionTitle from "../../../components/PageSectionTitle";
import SkeletonText from "../../../components/Skeleton/SkeletonText";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest, {
  tUseGetRequest,
} from "../../../hooks/apiRequests/useGetRequest";
import { tReservation } from "../../../models/reservation";
import { apiAddress } from "../../../utils/apiCall";
import { add2Arrays } from "../../../utils/arrays";
import { COLORS } from "../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
  FORCE_ENERGY_MEASURE_UNIT,
  FORCE_FOOTPRINT_MEASURE_UNIT,
  FORCE_WATER_MEASURE_UNIT,
} from "../../../utils/convertUnits/";
import { nUnitConverter } from "../../../utils/convertUnits/interfaces";
import { generateGraphCategories } from "../../../utils/graphs";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import {
  getCO2AndCostsPerMeasure,
  getMeasureInfo,
} from "../../../utils/measures";
import { tGuestReservations } from "./guest";

type tStayConsumptionRequest = {
  total: { co2: number[]; costs: number[] };
  te: { measurements: number[]; co2: number[]; costs: number[] };
  tw: { measurements: number[]; co2: number[]; costs: number[] };
};

type tSegment = {
  label: string;
  key: string;
  path: string;
  converter: (
    value: number
  ) => Pick<ReturnType<nUnitConverter.tFunction>, "value" | "unitInHTML">;
};

interface iProps {
  staysRequest: tUseGetRequest<tGuestReservations>;
}

const ActiveStays: React.FC<iProps> = ({ staysRequest }) => {
  const { trans } = useLocalizationState();
  const { hotel } = useHotelState();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);
  const [selectedStayIndex] = useState(0);

  const stayConsumptionRequest = useGetRequest<tStayConsumptionRequest>({
    total: { costs: [], co2: [] },
    te: { measurements: [], co2: [], costs: [] },
    tw: { measurements: [], co2: [], costs: [] },
  });

  useEffect(() => {
    if (staysRequest.isResolved) {
      if (staysRequest.data.reservations.ongoing.length > 0) {
        const selectedStay =
          staysRequest.data.reservations.ongoing[selectedStayIndex];

        stayConsumptionRequest.pending();
        axios
          .get(
            `${apiAddress(false)}/v2/stays/${selectedStay._id}/consumption`,
            { params: { binUnit: "day", binValue: 1 } }
          )
          .then((res) => {
            const {
              data: { measures },
            } = res;

            const { te, tw } = getCO2AndCostsPerMeasure(hotel, measures);

            const costs = add2Arrays(te?.costs || [], tw?.costs || []);
            const co2 = add2Arrays(te?.co2 || [], tw?.co2 || []);

            stayConsumptionRequest.resolve({
              total: { co2, costs },
              te: {
                measurements: measures.te,
                costs: te?.costs || [],
                co2: te?.co2 || [],
              },
              tw: {
                measurements: measures.tw,
                costs: tw?.costs || [],
                co2: tw?.co2 || [],
              },
            });
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            stayConsumptionRequest.reject(error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStayIndex, staysRequest.isResolved, trans]);

  const segments: tSegment[] = [
    {
      label: "Electricity",
      key: "te",
      path: "te.measurements",
      converter: convertEnergyUnit,
    },
    {
      label: "Water",
      key: "tw",
      path: "tw.measurements",
      converter: convertVolumeUnit,
    },
    {
      label: "CO2 Emissions",
      key: "co2",
      path: "total.co2",
      converter: convertMassUnit,
    },
    {
      label: "Costs",
      key: "costs",
      path: "total.costs",
      converter: (value: number) => ({
        value,
        unitInHTML: "€",
      }),
    },
  ];

  const renderActiveStay = (stay: tReservation) => {
    const renderSegmentValue = (
      segmentElement: (props: {
        valueColor: string;
        path: tSegment["path"];
        converter: tSegment["converter"];
      }) => React.ReactElement,
      graphElement: (key: tSegment["key"]) => React.ReactElement
    ) => {
      const { key } = segments[selectedSegmentIndex];
      return (
        <Flex one column gap={12}>
          <Flex gap={4} row>
            <InterTag
              color={COLORS.secondary}
              size={14}
              text={moment(stay.startAt).format("DD MMM, YYYY")}
            />
            <InterTag color={COLORS.secondary} size={14} text={"-"} />
            <InterTag
              color={COLORS.secondary}
              size={14}
              text={moment(stay.endAt).format("DD MMM, YYYY")}
            />
          </Flex>
          <Flex row gap={40}>
            {segments.map(({ label, converter, key, path }, i) => {
              let labelColor = COLORS.gray;
              let valueColor = COLORS.secondary;

              if (selectedSegmentIndex === i) {
                labelColor = COLORS.primary;
                valueColor = COLORS.primary;
              }

              return (
                <Flex
                  key={key}
                  column
                  gap={8}
                  className="hover-darken-white-bg"
                  style={{
                    cursor: "pointer",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                  onClick={() => {
                    if (selectedSegmentIndex !== i) setSelectedSegmentIndex(i);
                  }}
                >
                  <InterTag size={12} color={labelColor} text={trans(label)} />
                  {segmentElement({ valueColor, converter, path })}
                </Flex>
              );
            })}
          </Flex>
          {graphElement(key)}
        </Flex>
      );
    };

    if (stayConsumptionRequest.isLoading)
      return renderSegmentValue(
        () => <SkeletonText width={70} height={36} />,
        () => <SkeletonText width={"100%"} height={"300px"} />
      );
    if (stayConsumptionRequest.isResolved)
      return renderSegmentValue(
        ({ valueColor, converter, path }) => {
          const converted = converter(
            // @ts-expect-error
            sum(at(stayConsumptionRequest.data, path)[0])
          );

          return (
            <Flex row gap={4} bottom>
              <InterTag
                size={36}
                color={valueColor}
                text={round(converted.value, 2)}
              />
              <InterTag
                size={16}
                color={valueColor}
                text={converted.unitInHTML}
                asHTML
              />
            </Flex>
          );
        },
        (key) => {
          const categories = generateGraphCategories(
            "day",
            1,
            stay.startAt,
            stay.endAt
          );
          const series: any[] = [];
          let unit: string = "";

          const teInfo = getMeasureInfo("te");
          const twInfo = getMeasureInfo("tw");
          if (key === "te") {
            series.push({
              name: "Electricity",
              color: teInfo.color,
              data: stayConsumptionRequest.data.te.measurements.map((v) =>
                round(convertEnergyUnit(v).value, 2)
              ),
            });
            unit = FORCE_ENERGY_MEASURE_UNIT;
          } else if (key === "tw") {
            series.push({
              name: "Water",
              color: twInfo.color,
              data: stayConsumptionRequest.data.tw.measurements.map((v) =>
                round(convertVolumeUnit(v).value, 2)
              ),
            });
            unit = FORCE_WATER_MEASURE_UNIT;
          } else if (key === "co2") {
            series.push(
              {
                name: "Total CO2",
                color: COLORS.esg_environment,
                data: stayConsumptionRequest.data.total.co2.map((v) =>
                  round(convertMassUnit(v).value, 2)
                ),
              },
              {
                name: "Electricity CO2",
                color: teInfo.color,
                data: stayConsumptionRequest.data.te.co2.map((v) =>
                  round(convertMassUnit(v).value, 2)
                ),
              },
              {
                name: "Water CO2",
                color: twInfo.color,
                data: stayConsumptionRequest.data.tw.co2.map((v) =>
                  round(convertMassUnit(v).value, 2)
                ),
              }
            );
            unit = FORCE_FOOTPRINT_MEASURE_UNIT;
          } else if (key === "costs") {
            series.push(
              {
                name: "Total Costs",
                color: COLORS.esg_environment,
                data: stayConsumptionRequest.data.total.costs.map((v) =>
                  round(v, 2)
                ),
              },
              {
                name: "Electricity Costs",
                color: teInfo.color,
                data: stayConsumptionRequest.data.te.costs.map((v) =>
                  round(v, 2)
                ),
              },
              {
                name: "Water Costs",
                color: twInfo.color,
                data: stayConsumptionRequest.data.tw.costs.map((v) =>
                  round(v, 2)
                ),
              }
            );
            unit = "€";
          }

          return (
            <ApexChart
              type="line"
              series={series}
              legend={{
                show: true,
                position: "bottom",
                showForSingleSeries: true,
              }}
              xaxis={{
                categories,
                labels: {
                  show: true,
                  formatter(value, timestamp, opts) {
                    return moment(value).format("DD/MMM/YYYY");
                  },
                },
              }}
              yaxis={{
                title: {
                  text: `(${unit})`,
                  style: {
                    color: COLORS.gray_500,
                    fontSize: "10px",
                    fontFamily: "Inter",
                    fontWeight: "normal",
                  },
                  rotate: 0,
                  offsetX: 25,
                  offsetY: -120,
                },
              }}
            />
          );
        }
      );
  };

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={trans("Active Stay")}
        description={trans(
          "Data and interactions for the ongoing guest experiences"
        )}
        icon={HotelIcon}
      />
      <Flex
        className={
          staysRequest.data.reservations.ongoing.length > 0 &&
          stayConsumptionRequest.isResolved
            ? "card-m-no_bottom_padding"
            : "card-m"
        }
      >
        {staysRequest.isLoading ? (
          <Flex middle center one>
            <Loader size="md" />
          </Flex>
        ) : staysRequest.isRejected ? null : staysRequest.data.reservations
            .ongoing.length === 0 ? (
          <InterTag
            size={14}
            color={COLORS.secondary}
            text={trans("No active stays")}
          />
        ) : (
          renderActiveStay(
            staysRequest.data.reservations.ongoing[selectedStayIndex]
          )
        )}
      </Flex>
    </Flex>
  );
};

export default ActiveStays;

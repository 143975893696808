import { orderBy, round } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, TableProps } from "rsuite";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../../assets/icons/meeting_room.svg";
import { ReactComponent as PaymentsIcon } from "../../../../assets/icons/payments.svg";
import { ReactComponent as PsychiatryIcon } from "../../../../assets/icons/psychiatry.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import SimpleActionCell, {
  tSimpleActionOption,
} from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleLoadingCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleLoadingCell";
import SimpleTextCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "../../../../interfaces/apiCalls";
import { tHotelSpace } from "../../../../models/hotel";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import {
  convertEnergyUnit,
  convertVolumeUnit,
} from "../../../../utils/convertUnits";

export type tTableDataItem = Pick<tHotelSpace, "_id" | "name"> & {
  te: number;
  tw: number;
  costs: number;
  co2: number;
  waterIsMeasured: boolean;
  energyIsMeasured: boolean;
};

interface iProps extends TableProps<tTableDataItem, string> {
  options: tSimpleActionOption;
  waterIsMeasured: boolean;
  energyIsMeasured: boolean;
  spaceWidth: number;
  costsWidth: number;
  co2Width: number;
  teWidth: number;
  twWidth: number;
  data: tTableDataItem[];
  dataStatus: nRequestStatus.tStatus;
}

const Renderer: React.FC<iProps> = ({
  co2Width,
  costsWidth,
  data,
  dataStatus,
  energyIsMeasured,
  headerHeight,
  height,
  loading,
  options,
  spaceWidth,
  rowHeight,
  teWidth,
  twWidth,
  waterIsMeasured,
}) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const [list, setList] = useState<tTableDataItem[]>([]);
  const [{ sortColumn, sortType }, setSort] = useState<
    Pick<TableProps<tTableDataItem, string>, "sortType" | "sortColumn">
  >({ sortColumn: "costs", sortType: "desc" });

  useEffect(() => {
    setList(orderBy(data, ["costs"], ["desc"]));
  }, [data]);

  return (
    <Table
      id="TABLE.SPACE-AGG.SPACES"
      onSortColumn={(dataKey: string, sortType?: "desc" | "asc") => {
        if (sortType) {
          setSort({ sortColumn: dataKey, sortType });
          setList(orderBy(list, [dataKey], [sortType]));
        } else {
          setSort({ sortColumn: undefined, sortType: undefined });
          setList([...data]);
        }
      }}
      {...{
        loading,
        headerHeight,
        rowHeight,
        data: list,
        height,
        sortColumn,
        sortType,
      }}
    >
      <Table.Column width={spaceWidth}>
        <SimpleHeaderCell
          icon={MeetingRoomIcon}
          name={trans("general.space")}
        />
        <SimpleTextCell
          dataKey="name"
          textProps={(space) => ({
            hoverUnderline: true,
            onClick() {
              navigate(`/spaces/${space._id}`);
            },
          })}
        />
      </Table.Column>
      <Table.Column sortable width={costsWidth}>
        <SimpleHeaderCell
          icon={PaymentsIcon}
          name={`${trans("general.costs")}  (€)`}
        />
        {dataStatus === REQUEST_STATUS.PENDING ? (
          <SimpleLoadingCell dataKey="costs" />
        ) : (
          <SimpleTextCell
            dataKey="costs"
            textFunction={(rowData) => `${rowData["costs"]}`}
          />
        )}
      </Table.Column>
      <Table.Column sortable width={co2Width}>
        <SimpleHeaderCell
          icon={PsychiatryIcon}
          name={`${trans("Co2")}  (kg)`}
        />
        {dataStatus === REQUEST_STATUS.PENDING ? (
          <SimpleLoadingCell dataKey="co2" />
        ) : (
          <SimpleTextCell
            dataKey="co2"
            textFunction={(rowData) => `${rowData["co2"]}`}
          />
        )}
      </Table.Column>
      {energyIsMeasured && (
        <Table.Column sortable width={teWidth}>
          <SimpleHeaderCell
            icon={ElectricBoltIcon}
            name={trans("general.measures.electricity")}
          />
          {dataStatus === REQUEST_STATUS.PENDING ? (
            <SimpleLoadingCell dataKey="te" />
          ) : (
            <SimpleTextCell
              dataKey="te"
              textProps={() => ({ asHTML: true })}
              textFunction={(rowData) => {
                const converted = convertEnergyUnit(rowData["te"]);

                return `${round(converted.value, 2)} ${converted.unitInHTML}`;
              }}
            />
          )}
        </Table.Column>
      )}
      {waterIsMeasured && (
        <Table.Column sortable width={twWidth}>
          <SimpleHeaderCell
            icon={WaterDropIcon}
            name={{
              text: `${trans("general.measures.water")}`,
              asHTML: true,
            }}
          />
          {dataStatus === REQUEST_STATUS.PENDING ? (
            <SimpleLoadingCell dataKey="tw" />
          ) : (
            <SimpleTextCell
              dataKey="tw"
              textProps={() => ({ asHTML: true })}
              textFunction={(rowData) => {
                const converted = convertVolumeUnit(rowData["tw"]);

                return `${round(converted.value, 2)} ${converted.unitInHTML}`;
              }}
            />
          )}
        </Table.Column>
      )}
      <Table.Column flexGrow={1} align="right">
        <SimpleHeaderCell name={{ text: "" }} />
        <SimpleActionCell options={options} dataKey="_id" />
      </Table.Column>
    </Table>
  );
};

export default Renderer;

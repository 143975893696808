import React, { Fragment } from "react";
import { ReactComponent as ManageAccountIcon } from "../../../assets/icons/manage_accounts.svg";
import { ReactComponent as QueryStatsIcon } from "../../../assets/icons/query_stats.svg";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import PageSectionTitle from "../../../components/PageSectionTitle";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useListStaff from "../../../hooks/useListStaff";
import { tManager } from "../../../models/manager";
import { requestStatusIsFinal } from "../../../utils/apiCall";
import UserManagement from "./management";
import RecentUserActivities from "./recentActivities";

export type tStaffData = Pick<
  tManager,
  "_id" | "email" | "images" | "name" | "lastAccess" | "lastLogin"
>;

const UsersWrapped: React.FC = () => {
  const state = useListStaff();
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={40}>
      <Flex column gap={16}>
        <PageSectionTitle
          icon={{ Element: QueryStatsIcon }}
          title={trans("pages.users.sections.activities.title")}
          description={trans("pages.users.sections.activities.description")}
        />
        <RecentUserActivities
          {...{
            staff: state.data.staff,
            loading: !requestStatusIsFinal(state.status),
          }}
        />
      </Flex>
      <Flex column gap={16}>
        <PageSectionTitle
          icon={{ Element: ManageAccountIcon }}
          title={trans("pages.users.sections.management.title")}
          description={trans("pages.users.sections.management.description")}
        />
        <UserManagement
          {...{
            staffRequest: state,
          }}
        />
      </Flex>
    </Flex>
  );
};

const Users: React.FC = () => {
  return (
    <div>
      <TopBar page="general.users" />
      <IpValidatorGuard>
        <Fragment>
          <UsersWrapped />
          <PageBottomPadding />
        </Fragment>
      </IpValidatorGuard>
    </div>
  );
};

export default Users;

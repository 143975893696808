import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as StacksIcon } from "../../../assets/icons/stacks.svg";
import { ReactComponent as WorkspacesIcon } from "../../../assets/icons/workspaces.svg";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import Icon from "../../../components/Icon";
import MeasureConsumptionElectricity from "../../../components/MeasureConsumptionElectricity";
import MeasureConsumptionWater from "../../../components/MeasureConsumptionWater";
import PageBottomPadding from "../../../components/PageBottomPadding";
import SimpleDateRangePicker from "../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SimpleWhisperPopoverDropdown from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import useHotelMeasuresTrackedState from "../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useHotelSpacesState from "../../../context/Hotel/hooks/hotelState/useHotelSpacesState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePeriodState from "../../../hooks/usePeriodState";
import { tHotelSpace } from "../../../models/hotel";
import { COLORS } from "../../../utils/colors";

interface iProps {
  space: tHotelSpace;
}

const SpaceWrapped: React.FC<iProps> = ({ space }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { findSpaceSpaceAggregates } = useHotelSpacesState();
  const { getMeasuresTracked } = useHotelMeasuresTrackedState();

  const [period, setPeriod] = usePeriodState();

  const spaceGroups = findSpaceSpaceAggregates(space._id, "group");
  const spaceZones = findSpaceSpaceAggregates(space._id, "zone");
  const spaceTypes = findSpaceSpaceAggregates(space._id, "type");

  const trackedMeasures = getMeasuresTracked(space._id);

  return (
    <div>
      <SecondaryTopBar>
        <Flex row gap={8}>
          <SimpleDateRangePicker value={period} onChange={setPeriod} />
          {spaceGroups.length > 0 && (
            <SimpleWhisperPopoverDropdown
              options={spaceGroups.map((sg) => ({
                key: sg._id,
                label: sg.name,
                onClick() {
                  navigate(`/space-aggregates/${sg._id}`);
                },
              }))}
            >
              <Flex
                row
                gap={4}
                middle
                color={COLORS.white}
                style={{
                  borderRadius: "8px",
                  width: "fit-content",
                  padding: "9px",
                  cursor: "pointer",
                }}
                className="hover-box-shadow"
              >
                <Icon
                  Element={WorkspacesIcon}
                  size={16}
                  fill={COLORS.secondary}
                />
                <InterTag
                  size={14}
                  text={`${trans(
                    "general.space_categories_.short.groups"
                  )}: ${spaceGroups.map(({ name }) => name).join(", ")}`}
                />
              </Flex>
            </SimpleWhisperPopoverDropdown>
          )}
          {spaceZones.length > 0 && (
            <SimpleWhisperPopoverDropdown
              options={spaceZones.map((sz) => ({
                key: sz._id,
                label: sz.name,
                onClick() {
                  navigate(`/space-aggregates/${sz._id}`);
                },
              }))}
            >
              <Flex
                row
                gap={4}
                middle
                color={COLORS.white}
                style={{
                  borderRadius: "8px",
                  width: "fit-content",
                  padding: "9px",
                  cursor: "pointer",
                }}
                className="hover-box-shadow"
              >
                <Icon Element={StacksIcon} size={16} fill={COLORS.secondary} />
                <InterTag
                  size={14}
                  text={`${trans(
                    "general.space_categories_.short.zones"
                  )}: ${spaceZones.map(({ name }) => name).join(", ")}`}
                />
              </Flex>
            </SimpleWhisperPopoverDropdown>
          )}
          {spaceTypes.length > 0 && (
            <SimpleWhisperPopoverDropdown
              options={spaceTypes.map((st) => ({
                key: st._id,
                label: st.name,
                onClick() {
                  navigate(`/space-aggregates/${st._id}`);
                },
              }))}
            >
              <Flex
                row
                gap={4}
                middle
                color={COLORS.white}
                style={{
                  borderRadius: "8px",
                  width: "fit-content",
                  padding: "9px",
                  cursor: "pointer",
                }}
                className="hover-box-shadow"
              >
                <Icon
                  Element={CategoryIcon}
                  size={16}
                  fill={COLORS.secondary}
                />
                <InterTag
                  size={14}
                  text={`${trans(
                    "general.space_categories_.short.types"
                  )}: ${spaceTypes.map(({ name }) => name).join(", ")}`}
                />
              </Flex>
            </SimpleWhisperPopoverDropdown>
          )}
        </Flex>
      </SecondaryTopBar>
      <Flex column gap={40}>
        {trackedMeasures.te && (
          <MeasureConsumptionElectricity
            {...{
              ...(period ? { from: period[0], to: period[1] } : {}),
              spaces: [space._id],
            }}
          />
        )}
        {trackedMeasures.tw && (
          <MeasureConsumptionWater
            {...{
              ...(period ? { from: period[0], to: period[1] } : {}),
              spaces: [space._id],
            }}
          />
        )}
      </Flex>
    </div>
  );
};

const Space: React.FC = () => {
  const { spaceId } = useParams<{ spaceId: string }>();
  const { findSpace } = useHotelSpacesState();

  if (!spaceId) return <Navigate to="/spaces" />;

  const space = findSpace(spaceId);

  if (!space) return <Navigate to="/spaces" />;

  return (
    <>
      <TopBar
        page={space.name}
        parentPages={[{ transKey: "general.spaces", to: "/spaces" }]}
      />
      <IpValidatorGuard>
        <div>
          <SpaceWrapped {...{ space }} />
          <PageBottomPadding />
        </div>
      </IpValidatorGuard>
    </>
  );
};

export default Space;

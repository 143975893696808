import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, InputGroup, Loader } from "rsuite";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import { ReactComponent as PasswordIcon } from "../../../assets/icons/password.svg";
import { ReactComponent as VisibilityOn } from "../../../assets/icons/visibility.svg";
import { ReactComponent as VisibilityOff } from "../../../assets/icons/visibility_off.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import NoAuthDesktopWrapper from "../../../components/Pages/NoAuthWrapper/noAuthDesktopWrapper";
import NoAuthMobileWrapper from "../../../components/Pages/NoAuthWrapper/noAuthMobileWrapper";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import InterTag from "../../../components/Text/Inter";
import useAuthDispatch from "../../../context/Auth/hooks/useAuthDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfileDispatch from "../../../context/Profile/hooks/useProfileDispatch";
import useProfileState from "../../../context/Profile/hooks/useProfileState";
import useWindowSizeState from "../../../context/WindowSize/hooks/useWindowSizeState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { preventDefault } from "../../../utils/others";

const LoginWrapped: React.FC = () => {
  const { isTablet } = useWindowSizeState();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  const { login } = useAuthDispatch();
  const { setProfile } = useProfileDispatch();
  const { profileLoaded } = useProfileState();

  const [emailState, setEmailState] = useState<{
    value: string;
    error: string;
  }>({ value: "", error: "" });
  const [passwordState, setPasswordState] = useState<{
    value: string;
    error: string;
  }>({ value: "", error: "" });
  const [visibility, setVisibility] = useState(false);
  const postRequest = usePostRequest();

  const [preToken, setPreToken] = useState<null | string>(null);

  useEffect(() => {
    if (preToken && profileLoaded) login(preToken);
  }, [preToken, profileLoaded, login]);

  const onClickForgot = () => {
    navigate("/forgot-password");
  };

  const onClickLogin = () => {
    let error = false;
    if (!emailState.value) {
      setEmailState((prev) => ({
        ...prev,
        error: trans("Enter a valid email"),
      }));
      error = true;
    }

    if (error) return;

    postRequest.pending();
    axios
      .post(`${apiAddress(false)}/v2/managers/login`, {
        email: emailState.value,
        password: passwordState.value,
      })
      .then((res) => {
        const {
          data: { token, account },
        } = res;

        setProfile(account);
        setPreToken(token);
        postRequest.resolve({}, trans("pages.login.notifications.logged_in"));
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        postRequest.reject(error, true);
      });
  };

  const renderGreeting = (size: number) => (
    <Flex gap={8} column middle>
      <InterTag
        wrap
        bold
        text={trans("pages.login.greetings[0]")}
        size={size}
        color={COLORS.secondary}
      />
      <InterTag
        style={{ textAlign: "center" }}
        bold
        wrap
        text={trans("pages.login.greetings[1]")}
        size={size}
        color={COLORS.secondary}
      />
    </Flex>
  );

  const renderForm = () => (
    <form onSubmit={preventDefault(onClickLogin)}>
      <Flex column gap={16}>
        <InputWrapper
          required
          label={trans("general.email")}
          error={emailState.error}
        >
          <InputGroup>
            <InputGroup.Addon>
              <Icon
                Element={MailIcon}
                width={24}
                height={24}
                fill={COLORS.secondary}
              />
            </InputGroup.Addon>
            <Input
              value={emailState.value}
              onChange={(value) => setEmailState({ value, error: "" })}
              type="text"
              placeholder={trans("general.placeholders.email")}
              size="lg"
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper
          required
          label={trans("general.password")}
          error={passwordState.error}
        >
          <InputGroup>
            <InputGroup.Addon>
              <Icon
                Element={PasswordIcon}
                width={24}
                height={24}
                fill={COLORS.secondary}
              />
            </InputGroup.Addon>
            <Input
              value={passwordState.value}
              onChange={(value) => setPasswordState({ value, error: "" })}
              type={visibility ? "text" : "password"}
              placeholder={trans("MySecureP@ssw0rd")}
              size="lg"
            />
            <InputGroup.Button
              className={`hover-gray-100 bg-white`}
              onClick={() => setVisibility((prev) => !prev)}
            >
              {visibility ? (
                <Icon
                  Element={VisibilityOn}
                  width={24}
                  height={24}
                  fill={COLORS.gray}
                />
              ) : (
                <Icon
                  Element={VisibilityOff}
                  width={24}
                  height={24}
                  fill={COLORS.gray}
                />
              )}
            </InputGroup.Button>
          </InputGroup>
        </InputWrapper>
        <Flex right style={{ marginTop: "4px" }}>
          <InterTag
            onClick={onClickForgot}
            hoverUnderline
            text={trans("pages.login.forgot_password")}
            color={COLORS.primary}
            size={16}
          />
        </Flex>
      </Flex>
      <input type="submit" hidden />
    </form>
  );

  const renderSignup = (color: string) => (
    <Flex row gap={4} center>
      <InterTag color={color} text={trans("pages.login.goto_signup[0]")} />
      <InterTag
        color={COLORS.primary}
        text={trans("pages.login.goto_signup[1]")}
        hoverUnderline
        onClick={() => {
          navigate("/register");
        }}
      />
      <InterTag color={color} text={trans("pages.login.goto_signup[2]")} />
    </Flex>
  );

  const renderLoginButton = () => (
    <SimpleButton
      loading={postRequest.isLoading}
      onClick={onClickLogin}
      text={{
        text: trans("general.signin"),
        bold: true,
        color: COLORS.white,
        size: 20,
      }}
      appearance="primary"
    />
  );

  if (isTablet)
    return (
      <NoAuthDesktopWrapper>
        <Fragment>
          {postRequest.isLoading && <Loader center backdrop size="lg" />}
          <Flex column middle gap={16}>
            <Flex
              column
              center
              style={{
                backgroundColor: COLORS.light_white,
                borderRadius: "16px",
                padding: "64px",
                minWidth: "640px",
                maxWidth: "640px",
              }}
            >
              <Flex column center middle gap={32}>
                {renderGreeting(32)}
                <div className="width-100">{renderForm()}</div>
                <Flex column style={{ width: "100%" }}>
                  {renderLoginButton()}
                </Flex>
              </Flex>
            </Flex>
            {renderSignup(COLORS.white)}
          </Flex>
        </Fragment>
      </NoAuthDesktopWrapper>
    );

  return (
    <NoAuthMobileWrapper>
      <Flex column style={{ marginTop: "80px", padding: "0 20px" }} gap={32}>
        {renderGreeting(24)}
        <div style={{ padding: "0 8px" }}>{renderForm()}</div>
        <Flex column style={{ padding: "0 8px" }}>
          {renderLoginButton()}
        </Flex>
        {renderSignup(COLORS.secondary)}
      </Flex>
    </NoAuthMobileWrapper>
  );
};

const Login: React.FC = () => {
  return <LoginWrapped />;
};

export default Login;

import React from "react";
import { tIcon, tIconElement } from "../../interfaces/icon";
import { COLORS } from "../../utils/colors";
import Flex from "../Flex";
import Icon from "../Icon";
import { toIconProps } from "../Icon/utils";
import { iTextProps } from "../Text";
import InterTag from "../Text/Inter";
import { toTextProps } from "../Text/utils";
import useWindowSizeState from "../../context/WindowSize/hooks/useWindowSizeState";

export interface iPageSectionTitleProps {
  icon?: tIcon | tIconElement;
  title: string | iTextProps;
  description?: string | iTextProps;
  [key: string]: any;
}

const PageSectionTitle: React.FC<iPageSectionTitleProps> = ({
  icon,
  title,
  description,
  ...rest
}) => {
  const { isMobile } = useWindowSizeState();

  const [
    defaultIconSize,
    defaultTitleSize,
    defaultDescriptionSize,
    defaultDescriptionLineHeight,
  ] = isMobile ? [18, 18, 12, 14] : [24, 24, 14, 18];

  const renderDescription = () => {
    if (!description) return null;
    const descriptionProps = toTextProps(description);
    return (
      <InterTag
        {...{
          size: defaultDescriptionSize,
          color: COLORS.gray_600,
          wrap: true,
          ...descriptionProps,
          style: {
            ...descriptionProps.style,
            textAlign: "left",
            textWrap: "wrap",
            lineHeight: `${defaultDescriptionLineHeight}px`,
          },
        }}
      />
    );
  };

  return (
    <Flex column gap={8} style={{ ...rest.style }}>
      <Flex row middle gap={8}>
        {icon && (
          <Icon
            {...{
              fill: COLORS.secondary,
              size: defaultIconSize,
              ...toIconProps(icon),
            }}
          />
        )}
        <InterTag
          {...{
            wrap: true,
            size: defaultTitleSize,
            color: COLORS.secondary,
            ...toTextProps(title),
          }}
        />
      </Flex>
      {description && renderDescription()}
    </Flex>
  );
};

export default React.memo(PageSectionTitle);

import React, { PropsWithChildren } from "react";
import { ReactComponent as NoytrallSymbol } from "../../../assets/noytrall_symbol.svg";
import { ReactComponent as NoytrallWord } from "../../../assets/noytrall_word.svg";
import { COLORS } from "../../../utils/colors";
import Flex from "../../Flex";
import Icon from "../../Icon";

const NoAuthDesktopWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      id="no-auth-desktop-wrapper"
      className="height-100 bg-secondary"
      column
      gap={24}
    >
      <Flex row gap={10} center middle style={{ marginTop: "24px" }}>
        <Icon Element={NoytrallWord} width={100} fill={COLORS.gray_0} />
        <Icon Element={NoytrallSymbol} height={50} />
      </Flex>
      <div style={{ backgroundColor: COLORS.secondary, paddingBottom: "24px" }}>
        {children}
      </div>
    </Flex>
  );
};

export default NoAuthDesktopWrapper;

import { tHotel, tHotelSubscriptionModel } from "../../models/hotel";

export const BILLING_TYPES: tHotelSubscriptionModel["type"][] = [
  "starter",
  "business",
  "enterprise",
];

export const defaultHotel: tHotel = {
  _id: "",
  guestApp: { name: "", description: "", images: [] },

  waterToCurrency: [],
  waterToFootprint: [],
  energyToCurrency: [],
  energyToFootprint: [],

  loyaltyProgram: [],
  performanceProgram: [],

  tourismRegister: { type: "", _id: 0 },

  awards: [],
  challenges: [],

  spaces: [],
  spaceAggregates: [],

  staffRoles: [],
  staff: [],

  roadmap: [],

  name: "",
  email: "",
  phoneNumber: "",

  accommodationType: "",
  accommodationNumberOfUnits: 0,
  bills: [],
  country: "",
  description: "",
  guestRecords: [],
  images: [],
  isDummyHotel: false,
  location: {
    address: "",
    mapsUrl: "",
    coordinates: [0, 0],
    country: "",
    county: "",
    district: "",
    postalCode: "",
  },
  readings: { energy: [], gas: [], water: [] },
  fleet: [],
  reports: [],
  website: "",
  timestreamDBName: "",
  hasTimestreamDB: false,

  manualData: [],
  isSetup: false,

  settings: {
    roadmap: true,
    pages: {
      roadmap: { show: true },
      spaces: {
        consumption: {
          co2: { show: true },
          electricity: { show: true },
          water: { show: true },
          costs: { show: true },
        },
      },
    },
  },

  subscriptions: [
    // {
    //   type: "starter",
    //   startAt: moment().toDate(),
    //   active: true,
    //   timestamp: moment().toDate(),
    // },
  ],
  firstSubscriptionIsSet: false,
  billing: [],

  ipRestrictions: {
    whiteList: { active: false, ips: [] },
    blackList: { active: false, ips: [] },
  },

  invoices: [],
  paymentMethods: [],

  stripe: { customerId: "", defaultCard: "", invoices: [] },
  stripeLive: { customerId: "", defaultCard: "", invoices: [] },
  stripeTest: { customerId: "", defaultCard: "", invoices: [] },

  createdAt: new Date(),
  updatedAt: new Date(),
  isHotel: true,

  isVerified: false,
};

import { MomentInput } from "moment";
import React from "react";
import useHotelMeasuresTrackedState from "../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import { tHotelSpaceId } from "../../models/hotel";
import {
  MEASURES_ELECTRICITY_SPECIFIC,
  TE,
  tMeasureEnergySpecific,
} from "../../models/measures";
import {
  convertEnergyUnit,
  DEFAULT_ENERGY_MEASURE_UNIT,
} from "../../utils/convertUnits/";
import MeasureConsumption, {
  iMeasureConsumptionProps,
} from "../MeasureConsumption";

interface iProps
  extends Pick<iMeasureConsumptionProps, "measuresRequestStatusTracker"> {
  from?: MomentInput;
  to?: MomentInput;
  spaces?: tHotelSpaceId[];
}

const MeasureConsumptionElectricity: React.FC<iProps> = ({
  from,
  to,
  spaces,
  measuresRequestStatusTracker,
}) => {
  const { getMeasuresTracked } = useHotelMeasuresTrackedState();

  const trackedMeasures = getMeasuresTracked(spaces);

  const specificMeasures: { measureKey: tMeasureEnergySpecific }[] = [];
  MEASURES_ELECTRICITY_SPECIFIC.forEach((m) => {
    if (trackedMeasures[m]) specificMeasures.push({ measureKey: m });
  });

  return (
    <MeasureConsumption
      {...{
        measuresRequestStatusTracker,
      }}
      spaces={spaces}
      mainMeasureKey={TE}
      specificMeasures={specificMeasures}
      converter={convertEnergyUnit}
      binUnit="day"
      binValue={1}
      mainUnit={DEFAULT_ENERGY_MEASURE_UNIT}
      from={from}
      to={to}
    />
  );
};

export default MeasureConsumptionElectricity;

import axios from "axios";
import { cloneDeep, isNumber } from "lodash";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Loader, Toggle, useToaster } from "rsuite";
import { ReactComponent as CheckBoxFilledIcon } from "../../../assets/icons/check_box_filled.svg";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/keyboard_arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../assets/icons/keyboard_arrow_up.svg";
import { ReactComponent as NoytrallSymbol } from "../../../assets/noytrall_symbol.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import NoAuthDesktopWrapper from "../../../components/Pages/NoAuthWrapper/noAuthDesktopWrapper";
import NoAuthMobileWrapper from "../../../components/Pages/NoAuthWrapper/noAuthMobileWrapper";
import InterTag from "../../../components/Text/Inter";
import useAuthDispatch from "../../../context/Auth/hooks/useAuthDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../context/WindowSize/hooks/useWindowSizeState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import tHotel, { tHotelSubscriptionModel } from "../../../models/hotel";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { defaultHotel } from "../../../utils/hotels";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";
import BusinessPaymentModal from "./businessPaymentModal";

export type tBillingTimePeriod = "monthly" | "annually";

interface iProps {}

const SelectPlan: React.FC<iProps> = () => {
  const toaster = useToaster();
  const navigate = useNavigate();
  const { isLaptop } = useWindowSizeState();
  const { trans } = useLocalizationState();
  const { login } = useAuthDispatch();
  const [planExpanded, setPlanExpanded] = useState(-1);
  const [billingTimePeriod, setBillingTimePeriod] =
    useState<tBillingTimePeriod>("monthly");
  const getLocalState = useGetRequest<{
    planToken: string;
    hotel: tHotel;
  }>({
    planToken: "",
    hotel: cloneDeep(defaultHotel),
  });
  const [businessPaymentModal, setBusinessPaymentModal] = useState({
    open: false,
  });
  const starterRequest = usePostRequest();

  useEffectSafe(() => {
    getLocalState.resolve();
    try {
      if (getLocalState.isLoading) {
        const planToken = sessionStorage.getItem("plan-token");
        const hotel = sessionStorage.getItem("plan-hotel");
        if (planToken && hotel) {
          getLocalState.resolve({ planToken, hotel: JSON.parse(hotel) });
        } else {
          navigate("/");
        }
      }
    } catch (err) {
      navigate("/");
    }
  }, [getLocalState.isLoading]);

  const handleSelectStarter = () => {
    starterRequest.pending();
    axios.defaults.headers.common["token"] = getLocalState.data.planToken;
    axios
      .post(
        `${apiAddress(false)}/v2/hotels/${
          getLocalState.data.hotel._id
        }/confirm-first-plan/starter`
      )
      .then((res) => {
        login(getLocalState.data.planToken);
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        starterRequest.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  if (getLocalState.isLoading) {
    if (isLaptop)
      return (
        <NoAuthDesktopWrapper>
          <Flex center middle>
            <Flex
              center
              middle
              style={{
                backgroundColor: COLORS.light_white,
                borderRadius: "16px",
                padding: "32px 64px",
                width: "80%",
              }}
            >
              <Loader size="lg" />
            </Flex>
          </Flex>
        </NoAuthDesktopWrapper>
      );

    return (
      <NoAuthMobileWrapper>
        <Flex
          id="123"
          center
          middle
          style={{
            backgroundColor: COLORS.light_white,
            width: "100%",
            height: "100%",
          }}
        >
          <Loader size="lg" />
        </Flex>
      </NoAuthMobileWrapper>
    );
  }

  const renderTitle = (size: number) => (
    <Flex column middle>
      <InterTag
        alignCenter
        wrap
        text={trans("pages.register_plan.title")}
        size={size}
        color={COLORS.secondary}
        bold
      />
    </Flex>
  );

  const list: {
    key: tHotelSubscriptionModel["type"];
    transKeyword: string;
    numberOfPerks: number;
    price: Record<tBillingTimePeriod, number | string>;
    priceDescription: Record<tBillingTimePeriod, string>;
    perksCheckColor: string;
    button: () => JSX.Element;
  }[] = [
    {
      key: "starter",
      price: { monthly: 0, annually: 0 },
      priceDescription: {
        monthly: trans("general.free"),
        annually: trans("general.free"),
      },
      transKeyword: "starter",
      numberOfPerks: 3,
      perksCheckColor: COLORS.primary,
      button: () => (
        <Button
          appearance="primary"
          style={{ width: "80%" }}
          loading={starterRequest.isLoading}
          onClick={(e) => {
            e.stopPropagation();
            handleSelectStarter();
          }}
        >
          <strong style={{ fontSize: "16px" }}>{trans("Select")}</strong>
        </Button>
      ),
    },
    {
      key: "business",
      transKeyword: "business",
      numberOfPerks: 4,
      price: { monthly: 50, annually: 45 },
      priceDescription: {
        monthly: trans("pages.billing_plan.billed_monthly"),
        annually: trans("pages.billing_plan.per_month_billed_annually"),
      },
      perksCheckColor: COLORS.primary,
      button: () => (
        <Button
          appearance="primary"
          style={{ width: "80%" }}
          disabled={starterRequest.isLoading}
          onClick={(e) => {
            e.stopPropagation();
            setBusinessPaymentModal({ open: true });
          }}
        >
          <strong style={{ fontSize: "16px" }}>{trans("Select")}</strong>
        </Button>
      ),
    },
    {
      key: "enterprise",
      transKeyword: "enterprise",
      numberOfPerks: 5,
      price: { monthly: "Custom", annually: "Custom" },
      priceDescription: {
        monthly: trans("pages.billing_plan.billed_monthly"),
        annually: trans("pages.billing_plan.per_month_billed_annually"),
      },
      perksCheckColor: COLORS.primary,
      button: () => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            window.open(
              "https://supercal.io/miguelrolinhoclemente/noytrall",
              "_blank",
              "noreferrer"
            );
          }}
          appearance="ghost"
          style={{ width: "80%" }}
          disabled={starterRequest.isLoading}
        >
          <strong style={{ fontSize: "16px" }}>{trans("Contact Us")}</strong>
        </Button>
      ),
    },
  ];
  const renderCardsDesktop = () => {
    return list.map(
      ({
        key,
        numberOfPerks,
        transKeyword,
        perksCheckColor,
        price,
        priceDescription,
        button,
      }) => {
        const title = trans(`pages.billing_plan.plans.${transKeyword}.title`);
        const description = trans(
          `pages.billing_plan.plans.${transKeyword}.description`
        );

        const perks: string[] = [];
        for (let i = 0; i < numberOfPerks; i++) {
          perks.push(
            trans(`pages.billing_plan.plans.${transKeyword}.perks[${i}]`)
          );
        }
        return (
          <Flex key={key} column between gap={24} className="card-m" one>
            <Flex column gap={24}>
              <Flex column gap={8}>
                <Flex row gap={8} middle>
                  <Icon Element={NoytrallSymbol} size={30} />
                  <InterTag
                    text={trans(title)}
                    size={20}
                    bold
                    color={COLORS.secondary}
                  />
                </Flex>
                <InterTag
                  text={trans(description)}
                  color={COLORS.gray}
                  size={14}
                  style={{ textWrap: "wrap", textAlign: "left" }}
                />
              </Flex>
              <Flex column gap={16} middle>
                <Flex row gap={3} bottom>
                  <InterTag
                    size={48}
                    text={price[billingTimePeriod]}
                    color={COLORS.secondary}
                  />
                  {isNumber(price[billingTimePeriod]) && (
                    <InterTag size={18} text={"€"} color={COLORS.secondary} />
                  )}
                </Flex>
                <InterTag
                  text={priceDescription[billingTimePeriod]}
                  size={14}
                  color={COLORS.gray}
                />
              </Flex>
              <Flex column gap={12}>
                {perks.map((perk) => (
                  <Flex row gap={16} middle key={perk}>
                    <div>
                      <Icon
                        Element={CheckBoxFilledIcon}
                        fill={perksCheckColor}
                        size={20}
                      />
                    </div>
                    <InterTag
                      size={14}
                      colro={COLORS.secondary}
                      text={trans(perk)}
                      style={{ textWrap: "wrap", lineHeight: "18px" }}
                    />
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Flex center middle>
              {button()}
            </Flex>
          </Flex>
        );
      }
    );
  };

  const renderCardsMobile = () => {
    return list.map(
      (
        {
          button,
          key,
          numberOfPerks,
          perksCheckColor,
          price,
          priceDescription,
          transKeyword,
        },
        i
      ) => {
        const isExpanded = planExpanded === i;
        const title = trans(`pages.billing_plan.plans.${transKeyword}.title`);
        const description = trans(
          `pages.billing_plan.plans.${transKeyword}.description`
        );

        const priceText = isNumber(price[billingTimePeriod])
          ? price[billingTimePeriod] === 0
            ? trans("general.free")
            : `${price[billingTimePeriod]} € / ${trans(
                `general.${billingTimePeriod === "monthly" ? "month" : "year"}`
              )}`
          : trans("general.custom");

        const perks: string[] = [];
        for (let i = 0; i < numberOfPerks; i++) {
          perks.push(
            trans(`pages.billing_plan.plans.${transKeyword}.perks[${i}]`)
          );
        }

        return (
          <Flex
            column
            gap={12}
            style={{
              border: `${COLORS.gray_400} 1px solid`,
              borderRadius: "16px",
              padding: "20px 10px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (isExpanded) return setPlanExpanded(-1);
              setPlanExpanded(i);
            }}
          >
            <Flex row between gap={8}>
              <Flex column basis={80} gap={4}>
                <InterTag
                  bold
                  size={16}
                  color={COLORS.secondary}
                  text={title}
                />
                <InterTag
                  size={12}
                  wrap
                  color={COLORS.secondary}
                  text={description}
                />
              </Flex>
              <Flex middle right row basis={20} gap={4}>
                <InterTag text={priceText} color={COLORS.secondary} size={14} />
                <Icon
                  Element={isExpanded ? ArrowUpIcon : ArrowDownIcon}
                  size={16}
                  fill={COLORS.secondary}
                />
              </Flex>
            </Flex>
            {isExpanded && (
              <Flex column gap={20}>
                <Flex column gap={8}>
                  {perks.map((perk) => (
                    <Flex row gap={16} middle key={perk}>
                      <div>
                        <Icon
                          Element={CheckBoxFilledIcon}
                          fill={perksCheckColor}
                          size={20}
                        />
                      </div>
                      <InterTag
                        size={14}
                        colro={COLORS.secondary}
                        text={trans(perk)}
                        style={{ textWrap: "wrap", lineHeight: "18px" }}
                      />
                    </Flex>
                  ))}
                </Flex>
                <Flex center middle>
                  {button()}
                </Flex>
              </Flex>
            )}
          </Flex>
        );
      }
    );
  };

  const mainRender = () => {
    if (isLaptop)
      return (
        <NoAuthDesktopWrapper>
          <Flex
            center
            middle
            style={{ backgroundColor: COLORS.secondary, paddingBottom: "24px" }}
          >
            <Flex
              column
              gap={16}
              style={{
                backgroundColor: COLORS.light_white,
                borderRadius: "16px",
                padding: "32px 64px",
                width: "80%",
                maxWidth: "1152px",
              }}
            >
              {renderTitle(20)}
              <Flex row gap={8} middle center>
                <InterTag
                  size={14}
                  color={COLORS.secondary}
                  text={trans("pages.billing_plan.billed_monthly")}
                />
                <Toggle
                  checked={billingTimePeriod === "annually"}
                  onChange={(checked) =>
                    setBillingTimePeriod(checked ? "annually" : "monthly")
                  }
                />
                <InterTag
                  size={14}
                  color={COLORS.secondary}
                  text={`${trans(
                    "pages.billing_plan.billed_annually"
                  )} (${trans("general.x_%_off", { parameters: [10] })})`}
                />
              </Flex>
              <Flex row gap={24}>
                {renderCardsDesktop()}
              </Flex>
            </Flex>
          </Flex>
        </NoAuthDesktopWrapper>
      );

    return (
      <NoAuthMobileWrapper>
        <Flex column gap={40} style={{ marginTop: "40px", padding: "0 20px" }}>
          <Flex column gap={16}>
            {renderTitle(16)}
            <Flex center>
              <Flex
                style={{
                  position: "relative",
                  borderRadius: "50px",
                  width: "fit-content",
                }}
                color={COLORS.gray_300}
                row
              >
                <div
                  style={{
                    position: "absolute",
                    width: "48%",
                    ...(billingTimePeriod === "monthly"
                      ? { left: "5px" }
                      : { right: "5px" }),
                    top: "5px",
                    bottom: "5px",
                    borderRadius: "50px",
                    backgroundColor: COLORS.white,
                    zIndex: 2,
                  }}
                ></div>
                <Flex
                  center
                  middle
                  style={{ height: "80px", padding: "0 30px", zIndex: 3 }}
                  onClick={() => setBillingTimePeriod("monthly")}
                >
                  <InterTag
                    text={trans("general.monthly")}
                    color={
                      billingTimePeriod === "monthly"
                        ? COLORS.primary
                        : COLORS.secondary
                    }
                    size={14}
                    bold
                  />
                </Flex>
                <Flex
                  center
                  middle
                  style={{ height: "80px", padding: "0 30px", zIndex: 3 }}
                  onClick={() => setBillingTimePeriod("annually")}
                  column
                  gap={4}
                >
                  <InterTag
                    text={trans("general.annually")}
                    color={
                      billingTimePeriod === "monthly"
                        ? COLORS.secondary
                        : COLORS.primary
                    }
                    size={14}
                    bold
                  />
                  <InterTag
                    text={trans("general.x_%_off", { parameters: [10] })}
                    size={10}
                    color={
                      billingTimePeriod === "monthly"
                        ? COLORS.secondary
                        : COLORS.primary
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex column gap={8}>
            {renderCardsMobile()}
          </Flex>
          <div />
        </Flex>
      </NoAuthMobileWrapper>
    );
  };

  return (
    <Fragment>
      <BusinessPaymentModal
        billingTimePeriod={billingTimePeriod}
        open={businessPaymentModal.open}
        onClose={() => setBusinessPaymentModal({ open: false })}
      />
      {mainRender()}
    </Fragment>
  );
};

export default SelectPlan;

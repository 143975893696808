import axios from "axios";
import moment from "moment";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ReactComponent as DateRangeIcon } from "../../../assets/icons/date_range.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import PageBottomPadding from "../../../components/PageBottomPadding";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelSpacesState from "../../../context/Hotel/hooks/hotelState/useHotelSpacesState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import { tReservation } from "../../../models/reservation";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import AverageConsumption from "./averageConsumption";
import Awards from "./awards";
import GuestInformation from "./guestInformation";

export type tRequest = {
  reservation: tReservation;
  fromApi: boolean;
};

const ReservationWrapped: React.FC = () => {
  const { trans } = useLocalizationState();
  const location = useLocation();
  const params = useParams<{ idCode: string }>();
  const { findSpace } = useHotelSpacesState();

  const reservationRequest = useGetRequest<tRequest>({
    reservation: location.state.reservation,
    fromApi: false,
  });

  useEffectSafe(() => {
    if (reservationRequest.data.fromApi === false) {
      axios
        .get(`${apiAddress(false)}/v2/stays/${params.idCode}/consumption`)
        .then((res) => {
          const {
            data: { reservation },
          } = res;
          reservationRequest.resolve({ reservation, fromApi: true });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);

          reservationRequest.reject(error, true);
        });
    }
  }, [params.idCode, reservationRequest.data.fromApi, trans]);

  const { reservation } = reservationRequest.data;

  const numberOfNights = moment(reservation.endAt)
    .endOf("day")
    .diff(moment(reservation.startAt).startOf("day"), "days");

  const space = findSpace(reservation.room);

  return (
    <>
      <TopBar
        page={reservation.code}
        parentPages={[{ transKey: "general.stays", to: "/guests" }]}
      />
      <Flex column gap={30}>
        <Flex row gap={16}>
          <Link to={`/spaces/${reservation.room}`}>
            <Flex row gap={8} className="card-s hover-box-shadow">
              <Icon
                Element={MeetingRoomIcon}
                fill={COLORS.secondary}
                size={20}
              />
              <InterTag
                color={COLORS.secondary}
                text={
                  space
                    ? space.name
                    : trans("Space $0", { parameters: [reservation.room] })
                }
              />
            </Flex>
          </Link>
          <Flex row gap={8} className="card-s">
            <Icon Element={DateRangeIcon} fill={COLORS.secondary} size={20} />
            <InterTag
              color={COLORS.secondary}
              text={`${moment(reservation.startAt)
                .format("MMM DD, YYYY (HH:mm)")
                .toLowerCase()} - ${moment(reservation.endAt)
                .format("MMM DD, YYYY (HH:mm)")
                .toLowerCase()}`}
            />
          </Flex>
          <Flex row gap={8} className="card-s">
            <Icon Element={DateRangeIcon} fill={COLORS.secondary} size={20} />
            <InterTag
              color={COLORS.secondary}
              text={`${numberOfNights} ${trans("nights")}`}
            />
          </Flex>
        </Flex>
        <GuestInformation
          {...{
            reservationId: reservation._id,
            mainGuest: reservation.mainGuest,
            additionalGuests: reservation.additionalGuests,
          }}
        />
        <AverageConsumption reservation={reservation} />
        <Awards reservationRequest={reservationRequest} />
      </Flex>
    </>
  );
};

const Reservation: React.FC = () => {
  return (
    <div>
      <ReservationWrapped />
      <PageBottomPadding />
    </div>
  );
};

export default Reservation;

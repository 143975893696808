import axios from "axios";
import { floor, min } from "lodash";
import React from "react";
import { Table } from "rsuite";
import { ReactComponent as ApprovalDelegationsIcon } from "../../../assets/icons/approval_delegation.svg";
import { ReactComponent as AvgPaceIcon } from "../../../assets/icons/avg_pace.svg";
import { ReactComponent as EnergyProgramTimeUsedIcon } from "../../../assets/icons/energy_program_time_used.svg";
import { ReactComponent as HotelClassIcon } from "../../../assets/icons/hotel_class-1.svg";
import { ReactComponent as TargetIcon } from "../../../assets/icons/target.svg";
import { ReactComponent as ToggleOffIcon } from "../../../assets/icons/toggle_off.svg";
import { ReactComponent as ToggleOnIcon } from "../../../assets/icons/toggle_on.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import Flex from "../../../components/Flex";
import PageSectionTitle from "../../../components/PageSectionTitle";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleAwardsCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleAwardsCell";
import SimpleTextCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePutRequest from "../../../hooks/apiRequests/usePutRequest";
import useScreenSize from "../../../hooks/useScreenSize";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { tHotelPerformanceProgramItem } from "../../../models/hotel";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";

type tTableItem = tHotelPerformanceProgramItem;

interface iPerformanceWrappedProps {
  outletContainerWidth: number;
}

const PerformanceWrapped: React.FC<iPerformanceWrappedProps> = ({
  outletContainerWidth,
}) => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { hotel, hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const putRequest = usePutRequest();

  const handleProgramItemToggleStatus = (
    elem: tHotelPerformanceProgramItem,
    status: tHotelPerformanceProgramItem["status"]
  ) => {
    if (elem.status === status) return;
    putRequest.pending();
    axios
      .put(
        `${apiAddress(false)}/v2/hotels/${hotelId}/performance/${elem._id}`,
        {
          status,
        }
      )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        toaster.success(
          trans(
            "pages.loyalty.sections.performance_program.notifications.status_updated"
          )
        );
        updateHotel(hotelId, hotel);
        putRequest.resolve();
      })
      .catch((err) => {
        const {
          data: { hotel },
        } = err.response;
        const error = getErrorMessage(err, trans);

        if (hotel) {
          updateHotel(hotelId, hotel);
        } else {
          toaster.error(error);
        }
        putRequest.reject(error);
      });
  };

  const options = (
    dataKey: string,
    tableItem: tTableItem
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    switch (tableItem.status) {
      case "inactive":
        options.push({
          key: "toggle status",
          label: { text: trans("general.activate"), color: COLORS.info },
          icon: { Element: ToggleOnIcon, fill: COLORS.info },
          onClick() {
            handleProgramItemToggleStatus(tableItem, "active");
          },
        });
        break;
      case "active":
        options.push({
          key: "toggle status",
          label: { text: trans("general.deactivate"), color: COLORS.info },
          icon: { Element: ToggleOffIcon, fill: COLORS.info },
          onClick() {
            handleProgramItemToggleStatus(tableItem, "inactive");
          },
        });
        break;

      default:
        break;
    }

    return options;
  };

  const tableData: tTableItem[] = hotel.performanceProgram;

  const rowHeight = 60;
  const headerHeight = 50;
  const tableHeight =
    (min([4, tableData.length || 1]) as number) * rowHeight + headerHeight;

  const levelWidth = floor(0.1 * outletContainerWidth);
  const goalWidth = floor(0.3 * outletContainerWidth);
  const awardsWidth = floor(0.3 * outletContainerWidth);
  const statusWidth = floor(0.1 * outletContainerWidth);
  const timesAwardedWidth = floor(0.1 * outletContainerWidth);

  return (
    <div className="table-wrapper">
      <Table
        id={"TABLE.PERFORMANCE"}
        height={tableHeight}
        data={tableData}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        loading={putRequest.isLoading}
      >
        <Table.Column width={levelWidth}>
          <SimpleHeaderCell
            icon={HotelClassIcon}
            name={trans("general.level")}
          />
          <SimpleTextCell dataKey="level" />
        </Table.Column>
        <Table.Column width={goalWidth}>
          <SimpleHeaderCell icon={TargetIcon} name={trans("general.goal")} />
          <SimpleTextCell
            dataKey="goal"
            textFunction={(rowData: tTableItem) =>
              trans(
                "pages.loyalty.sections.performance_program.table.columns.goal.text",
                {
                  parameters: [rowData.goal],
                }
              )
            }
            textProps={() => ({ style: { textWrap: "wrap" } })}
          />
        </Table.Column>
        <Table.Column width={awardsWidth}>
          <SimpleHeaderCell
            icon={VerifiedIcon}
            name={trans("general.awards")}
          />
          <SimpleAwardsCell dataKey="awards" />
        </Table.Column>
        <Table.Column width={statusWidth}>
          <SimpleHeaderCell icon={AvgPaceIcon} name={trans("general.status")} />
          <SimpleTextCell
            textProps={(rowData) => ({
              color:
                COLORS[rowData.status === "active" ? "emissions" : "error"],
            })}
            textFunction={(rowData) => {
              switch (rowData.status) {
                case "active":
                  return trans("general.active");
                case "inactive":
                  return trans("general.inactive");
                default:
                  break;
              }
            }}
            dataKey="status"
          />
        </Table.Column>
        <Table.Column width={timesAwardedWidth}>
          <SimpleHeaderCell
            icon={ApprovalDelegationsIcon}
            name={trans("general.awarded")}
          />
          <SimpleTextCell dataKey="timesAwarded" />
        </Table.Column>
        <Table.Column flexGrow={1} align="right">
          <SimpleHeaderCell name={{ text: "" }} />
          <SimpleActionCell options={options} dataKey="_id" />
        </Table.Column>
      </Table>
    </div>
  );
};

interface iProps {}

const PerformanceProgram: React.FC<iProps> = () => {
  const { outlet: oc } = useScreenSize();
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <Flex row bottom between>
        <PageSectionTitle
          icon={EnergyProgramTimeUsedIcon}
          title={trans("pages.loyalty.sections.performance_program.title")}
          description={trans(
            "pages.loyalty.sections.performance_program.description"
          )}
        />
      </Flex>
      {oc ? <PerformanceWrapped outletContainerWidth={oc.width} /> : null}
    </Flex>
  );
};

export default PerformanceProgram;

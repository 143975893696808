import axios from "axios";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import Flex from "../../../components/Flex";
import MeasureConsumptionElectricity from "../../../components/MeasureConsumptionElectricity";
import MeasureConsumptionWater from "../../../components/MeasureConsumptionWater";
import PageBottomPadding from "../../../components/PageBottomPadding";
import SimpleDateRangePicker from "../../../components/RsuiteWrapper/SimpleDateRangePicker";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import useHotelMeasuresTrackedState from "../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useHotelSpacesState from "../../../context/Hotel/hooks/hotelState/useHotelSpacesState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import usePeriodState from "../../../hooks/usePeriodState";
import { tHotelSpaceAggregate, tHotelSpaceId } from "../../../models/hotel";
import {
  TE,
  tMeasure,
  tMeasureTE,
  tMeasureTotal,
  tMeasureTW,
  TW,
} from "../../../models/measures";
import { apiAddress } from "../../../utils/apiCall";
import { add2Arrays } from "../../../utils/arrays";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import {
  getCO2AndCostsPerMeasure,
  groupDataBySpace,
} from "../../../utils/measures";
import List from "./List";

const BIN_UNIT = "day";
const BIN_VALUE = 1;

export type tData = {
  grouped: Record<"co2" | "costs", number[]> &
    Record<
      tMeasureTE | tMeasureTW,
      Record<"co2" | "costs" | "values", number[]>
    >;
  bySpace: Record<
    tHotelSpaceId,
    Record<"co2" | "costs", number[]> &
      Partial<
        Record<
          tMeasureTE | tMeasureTW,
          Record<"co2" | "costs" | "values", number[]>
        >
      >
  >;
};

interface iSpaceAggregateWrappedProps {
  spaceAggregate: tHotelSpaceAggregate;
}

const SpaceAggregateWrapped: React.FC<iSpaceAggregateWrappedProps> = ({
  spaceAggregate,
}) => {
  const { trans } = useLocalizationState();
  const [period, setPeriod] = usePeriodState();
  const { hotelId, hotel, getMeasuresTracked } = useHotelMeasuresTrackedState();
  const measuresRequest = useGetRequest<tData>({
    grouped: {
      co2: [],
      costs: [],
      te: { co2: [], costs: [], values: [] },
      tw: { co2: [], costs: [], values: [] },
    },
    bySpace: {},
  });

  const { tw: twIsTracked, te: teIsTracked } = getMeasuresTracked(
    spaceAggregate.spaces
  );

  const measuresList: tMeasure[] = [];
  if (twIsTracked) measuresList.push(TW);
  if (teIsTracked) measuresList.push(TE);

  useEffectSafe(() => {
    const run = () => {
      const required_measures: tMeasureTotal[] = [];
      if (twIsTracked) required_measures.push(TW);
      if (teIsTracked) required_measures.push(TE);
      axios
        .get(
          `${apiAddress(false)}/v2/hotels/${hotelId}/space-aggregates/${
            spaceAggregate._id
          }/consumption`,
          {
            params: {
              from: moment(period[0]).toISOString(),
              to: moment(period[1]).toISOString(),
              binUnit: BIN_UNIT,
              binValue: BIN_VALUE,
              measures: required_measures,
            },
          }
        )
        .then((res) => {
          const { dataBySpace } = res.data as {
            dataBySpace: Record<
              tHotelSpaceId,
              Partial<Record<tMeasureTotal, number[]>>
            >;
          };

          const grouped = (() => {
            const measuresGrouped = groupDataBySpace(dataBySpace);

            const grouped: tData["grouped"] = {
              co2: [],
              costs: [],
              te: {
                co2: [],
                costs: [],
                values: measuresGrouped.te || [],
              },
              tw: {
                co2: [],
                costs: [],
                values: measuresGrouped.tw || [],
              },
            };

            const co2AndCostsPerMeasure = getCO2AndCostsPerMeasure(
              hotel,
              measuresGrouped,
              period[0],
              period[1],
              BIN_UNIT,
              BIN_VALUE
            );

            grouped.te = { ...grouped.te, ...co2AndCostsPerMeasure.te };
            grouped.tw = { ...grouped.tw, ...co2AndCostsPerMeasure.tw };
            grouped.co2 = add2Arrays(grouped.te.co2, grouped.tw.co2);
            grouped.costs = add2Arrays(grouped.te.costs, grouped.tw.costs);

            return grouped;
          })();

          const bySpace: tData["bySpace"] = Object.fromEntries(
            Object.entries(dataBySpace).map((entry) => {
              const [spaceId, measurements] = entry as [
                tHotelSpaceId,
                Partial<Record<tMeasureTotal, number[]>>
              ];

              const co2CostsPerMeasure = getCO2AndCostsPerMeasure(
                hotel,
                measurements,
                period[0],
                period[1],
                BIN_UNIT,
                BIN_VALUE
              );

              return [
                spaceId,
                {
                  co2: add2Arrays(
                    co2CostsPerMeasure.te?.co2 || [],
                    co2CostsPerMeasure.tw?.co2 || []
                  ),
                  costs: add2Arrays(
                    co2CostsPerMeasure.te?.costs || [],
                    co2CostsPerMeasure.tw?.costs || []
                  ),
                  te: {
                    co2: [],
                    costs: [],
                    values: measurements.te || [],
                    ...co2CostsPerMeasure.te,
                  },
                  tw: {
                    co2: [],
                    costs: [],
                    values: measurements.tw || [],
                    ...co2CostsPerMeasure.tw,
                  },
                },
              ];
            })
          );

          measuresRequest.resolve({ bySpace, grouped });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          measuresRequest.reject(error);
        });
    };
    if (hotelId) run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, spaceAggregate._id, teIsTracked, trans, twIsTracked, hotelId]);

  return (
    <div>
      <SecondaryTopBar>
        <SimpleDateRangePicker value={period} onChange={setPeriod} />
      </SecondaryTopBar>
      <Flex column gap={40}>
        {teIsTracked && (
          <MeasureConsumptionElectricity
            {...{
              ...(period ? { from: period[0], to: period[1] } : {}),
              spaces: spaceAggregate.spaces,
            }}
          />
        )}
        {twIsTracked && (
          <MeasureConsumptionWater
            {...{
              ...(period ? { from: period[0], to: period[1] } : {}),
              spaces: spaceAggregate.spaces,
            }}
          />
        )}
        <List {...{ measuresRequest, spaceAggregate }} />
      </Flex>
    </div>
  );
};

const SpaceAggregate: React.FC = () => {
  const { spaceAggregateId } = useParams<{ spaceAggregateId: string }>();
  const { trans } = useLocalizationState();
  const { hotelId, findSpaceAggregate } = useHotelSpacesState();

  if (!spaceAggregateId) return null;

  if (!hotelId) return null;

  const spaceAggregate = findSpaceAggregate(spaceAggregateId);

  if (!spaceAggregate) return null;

  return (
    <div>
      <TopBar
        page={`${trans(
          `general.space_categories_.short.${spaceAggregate.category}`
        )}: ${spaceAggregate.name}`}
        parentPages={[{ transKey: "general.spaces", to: "/spaces" }]}
      />
      <SpaceAggregateWrapped {...{ spaceAggregate }} />
      <PageBottomPadding />
    </div>
  );
};

export default SpaceAggregate;

import { ReactComponent as BadgeIcon } from "../../assets/icons/badge.svg";
import { ReactComponent as DasboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { ReactComponent as DirectionsCarIcon } from "../../assets/icons/directions_car.svg";
import { ReactComponent as DomainIcon } from "../../assets/icons/domain.svg";
import { ReactComponent as ElectricBoltIcon } from "../../assets/icons/electric_bolt.svg";
import { ReactComponent as ElectricMeterIcon } from "../../assets/icons/electric_meter.svg";
import { ReactComponent as EnergySavingProgramIcon } from "../../assets/icons/energy_program_saving.svg";
import { ReactComponent as FavoriteIcon } from "../../assets/icons/favorite.svg";
import { ReactComponent as GroupIcon } from "../../assets/icons/group.svg";
import { ReactComponent as HotelIcon } from "../../assets/icons/hotel.svg";
import { ReactComponent as LocalLaundryServiceIcon } from "../../assets/icons/local_laundry_service.svg";
import { ReactComponent as MeetingRoomIcon } from "../../assets/icons/meeting_room.svg";
import { ReactComponent as ModeHeatIcon } from "../../assets/icons/mode_heat.svg";
import { ReactComponent as StadiaControllerIcon } from "../../assets/icons/stadia_controller.svg";
import { ReactComponent as StoreFrontIcon } from "../../assets/icons/storefront.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/icons/verified.svg";
import { ReactComponent as WaterDropIcon } from "../../assets/icons/water_drop.svg";
import { tIconElement } from "../../interfaces/icon";

export const MENU_MAP: Record<
  string,
  {
    icon: tIconElement;
    to: string;
    transKey: string;
  }
> = {
  properties: {
    icon: DomainIcon,
    to: "properties",
    transKey: "general.properties",
  },
  overview: {
    icon: DasboardIcon,
    to: "overview",
    transKey: "general.overview",
  },
  sustainability: {
    icon: EnergySavingProgramIcon,
    to: "sustainability",
    transKey: "general.sustainability",
  },
  spaces: {
    icon: MeetingRoomIcon,
    to: "spaces",
    transKey: "general.spaces",
  },
  guests: {
    icon: GroupIcon,
    to: "guests",
    transKey: "general.guests",
  },
  occupancy: {
    icon: HotelIcon,
    to: "occupancy",
    transKey: "general.occupancy",
  },
  consumption: {
    icon: ElectricMeterIcon,
    to: "consumption",
    transKey: "general.consumption",
  },
  "consumption.electricity": {
    icon: ElectricBoltIcon,
    to: "consumption/electricity",
    transKey: "general.measures.electricity",
  },
  "consumption.water": {
    icon: WaterDropIcon,
    to: "consumption/water",
    transKey: "general.measures.water",
  },
  "consumption.fuels": {
    icon: ModeHeatIcon,
    to: "consumption/fuels",
    transKey: "general.fuels",
  },
  "consumption.waste": {
    icon: DeleteIcon,
    to: "consumption/waste",
    transKey: "general.waste",
  },
  "consumption.laundry": {
    icon: LocalLaundryServiceIcon,
    to: "consumption/laundry",
    transKey: "general.laundry",
  },
  "consumption.fleet": {
    icon: DirectionsCarIcon,
    to: "consumption/fleet",
    transKey: "general.fleet",
  },

  awards: {
    icon: VerifiedIcon,
    to: "awards",
    transKey: "general.awards",
  },
  challenges: {
    icon: StadiaControllerIcon,
    to: "challenges",
    transKey: "general.challenges",
  },
  loyalty: {
    icon: FavoriteIcon,
    to: "loyalty",
    transKey: "general.loyalty",
  },
  users: {
    icon: BadgeIcon,
    to: "users",
    transKey: "general.users",
  },
  profile: {
    icon: StoreFrontIcon,
    to: "hotel-profile",
    transKey: "general.profile",
  },
};

import { sortBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ReactComponent as LocalLaundryServiceIcon } from "../../../assets/icons/local_laundry_service.svg";
import { ReactComponent as QueryStatsIcon } from "../../../assets/icons/query_stats.svg";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import ManualDataModal from "../../../components/Modals/ManualDataModal";
import PageBottomPadding from "../../../components/PageBottomPadding";
import PageSectionTitle from "../../../components/PageSectionTitle";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { tWithRequired } from "../../../interfaces/others";
import { tHotelManualData } from "../../../models/hotel";
import { COLORS } from "../../../utils/colors";
import Graph from "./graph";
import List from "./list";
import Unlock from "./unlock";

const LaundryWrapped: React.FC = () => {
  const { hotel } = useHotelState();
  const { trans } = useLocalizationState();
  const [manualDataModal, setManualDataModal] = useState({ open: false });
  const profilePermissions = useProfilePermissions();
  const readyManualData = useGetRequest<{
    list: tWithRequired<tHotelManualData, "laundry">[];
  }>({ list: [] });

  useEffect(() => {
    const parsed = hotel.manualData.filter((md) => md.laundry) as tWithRequired<
      tHotelManualData,
      "laundry"
    >[];

    const sorted = sortBy(parsed, (pmd) => moment(pmd.from).valueOf());

    readyManualData.resolve({ list: sorted });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel.manualData]);

  const segments = [
    {
      key: "overview",
      label: "Overview",
      pageSectionProps: {
        title: trans("pages.laundry.archive.sections.overview.title"),
        description: trans(
          "pages.laundry.archive.sections.overview.description"
        ),
        icon: QueryStatsIcon,
      },
      component: {
        Element: Graph,
        props: {},
      },
    },
    {
      key: "data",
      label: "Data",
      pageSectionProps: {
        title: trans("pages.laundry.archive.sections.list.title"),
        description: trans("pages.laundry.archive.sections.list.description"),
        icon: LocalLaundryServiceIcon,
      },
      component: {
        Element: List,
        props: { readyManualData },
      },
    },
  ];

  return (
    <>
      {profilePermissions.writeManualData && (
        <ManualDataModal
          open={manualDataModal.open}
          initialStep="laundry"
          singleStep
          onClose={() => setManualDataModal({ open: false })}
        />
      )}
      <div>
        <SecondaryTopBar>
          <div>
            {profilePermissions.writeManualData && (
              <SimpleButton
                appearance="primary"
                text={{
                  text: trans("components.manual_data.global_add_entry.label"),
                  color: COLORS.white,
                  size: 12,
                }}
                onClick={() => setManualDataModal({ open: true })}
              />
            )}
          </div>
        </SecondaryTopBar>
        <Flex column gap={40}>
          {segments.map(
            ({ component: { Element, props }, key, pageSectionProps }) => (
              <Flex column gap={16} key={key}>
                <PageSectionTitle {...pageSectionProps} />
                {/* @ts-ignore */}
                <Element {...props} />
              </Flex>
            )
          )}
        </Flex>
      </div>
    </>
  );
};

const LaundryGuarded: React.FC = () => {
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();

  if (activeSubscriptionIsStarter) return <Unlock />;

  return <LaundryWrapped />;
};

const Laundry: React.FC = () => {
  return (
    <div>
      <TopBar
        page="general.laundry"
        parentPages={[{ transKey: "general.consumption", to: "/consumption" }]}
      />
      <IpValidatorGuard>
        <div>
          <LaundryGuarded />
          <PageBottomPadding />
        </div>
      </IpValidatorGuard>
    </div>
  );
};

export default Laundry;
